import { Component, OnInit, Input } from '@angular/core';

import { UserLoginService } from 'src/app/services/user-login.service';


@Component({
    selector: 'app-configuration',
    templateUrl: './configuration.component.html',
    styleUrls: ['./configuration.component.scss'],
})
export class ConfigurationComponent implements OnInit {
    constructor(private userLoginService: UserLoginService) {}

    ngOnInit(): void {
        this.userLoginService.setLoginStatus(true);
    }
}
