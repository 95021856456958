import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-same-table',
    templateUrl: './confirm-same-table.component.html',
    styleUrls: ['./confirm-same-table.component.scss'],
})
export class ConfirmSameTableComponent implements OnInit {
    public message: string;

    constructor(public dialogRef: MatDialogRef<ConfirmSameTableComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {}

    close(): void {
        this.dialogRef.close({ event: 'close' });
    }

    confirmSameTable(): void {
        this.dialogRef.close({ event: 'validateWarning' });
    }
}
