import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent implements OnInit {
    formNameMapping: {};
    changedFields: {};
    initialFormValues: {};

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ConfirmationModalComponent>) {}

    ngOnInit() {
        if (this.data) {
            this.formNameMapping = this.data.formNameMapping || {};
            this.changedFields = this.data.changedFields || {};
            this.initialFormValues = this.data.initialFormValues || {};
        }
    }

    saveChanges(): void {
        this.dialogRef.close({ event: 'saveChanges' });
    }
}
