<h4 mat-dialog-title>Lettre recommandée selectionnée</h4>
<div mat-dialog-content class="message-block">
  <div class="fields-container">
    <h6>Attention !</h6>
    <div>
      <span>Vous avez sélectionné un affranchissement en Lettre recommandée.</span>
      <span> Souhaitez-vous confirmer cette sélection&nbsp;?</span>
    </div>
  </div>
  <div mat-dialog-actions class="buttons-container">
    <button mat-button mat-dialog-close class="cancel-btn">Annuler</button>
    <button mat-button (click)="saveChanges()" class="primary-btn">Confirmer</button>
  </div>
</div>