import { Component, OnInit, EventEmitter, Output, Input, Inject } from '@angular/core';
import { AwsService } from 'src/app/services/aws.service';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';
import { UserLoginService } from 'src/app/services/user-login.service';
import { MercureRoles } from 'src/app/enums/role.enum';

@Component({
    selector: 'app-resume',
    templateUrl: './resume.component.html',
    styleUrls: ['./resume.component.scss'],
})
export class ResumeComponent implements OnInit {
    @Input() com: any;
    @Output() previousStep = new EventEmitter<any>();
    public originalTitle: string;
    public blinkingTitleInterval: any;
    public elapsedTime: number = 0;
    data: any = {};
    total = 0;
    labelaffr = 'Standard';
    affr = { 'EGC': 'Standard', 'ELS': 'Lettre Suivie', 'RRA': 'Recommandé avec accusé de réception', 'RRS': 'Recommandé sans accusé de réception' };

    report: any = {};
    target = 0;
    loading = true;
    heure = undefined;
    public idlot;
    public displayWarning: boolean = false;
    isDataProcessing: boolean = false;
    dataProcessingTimeLeft: number = 15;
    lastStepInterval = undefined;
    interval: any;

    supports = [];
    recipientLabels = {
        'abonne': 'Abonné',
        'destinataire': 'Destinataire de facture',
    };
    public isTakingTooLong: boolean = false;
    public isMercureDemandeur: boolean = false;
    public displayTimeString: string;
    @Input() isHorsIris: boolean = false;

    constructor(public awsService: AwsService, @Inject(DOCUMENT) private document: any, private utilsService: UtilsService, private userLoginService: UserLoginService) {
        this.originalTitle = this.document.title;
        Notification.requestPermission().then((result) => {
            console.info('Résultat de la permission de notification:', result);
        });
        this.document.addEventListener('visibilitychange', () => {
            if (!document.hidden) {
                this.stopBlinkingTitle();
            }
        });
    }

    ngOnInit(): void {
        this.checkIfAdmin();
        this.lastStepInterval = this.com.lastStepInterval;
        this.idlot = this.com.idlot;
        this.startTimer(this.com.timeLeft);
        if (this.com.staggerCommunications) {
            this.heure = 'Envoi étalé de 8h à 17h';
        } else if (this.com.heureEmission) {
            this.heure = this.com.heureEmission.value?.replace(':', ' h ');
        }

        if (this.com.records) this.target = this.com.records.length;
        this.labelaffr = this.affr[this.com.affranchissement];
        setTimeout(() => {
            this.reload();
        }, 1000);
    }

    startTimer(duration) {
        this.dataProcessingTimeLeft = duration;
        this.isDataProcessing = true;
        this.interval = setInterval(() => {
            if (this.dataProcessingTimeLeft > 0) {
                this.dataProcessingTimeLeft--;
                this.elapsedTime++;
                this.displayTime();
            } else {
                this.isTakingTooLong = true;
                this.dataProcessingTimeLeft = 15; // Still processing ? Add 15 seconds
                this.elapsedTime++;
            }
        }, 1000);
        if (this.lastStepInterval) {
            clearInterval(this.lastStepInterval);
            this.lastStepInterval = undefined;
        }
    }

    displayTime() {
        let minutes = Math.floor(this.dataProcessingTimeLeft / 60);
        let seconds = this.dataProcessingTimeLeft % 60;
        this.displayTimeString = '';

        if (minutes > 0) {
            this.displayTimeString = `${minutes}m ${seconds}s`;
        } else {
            this.displayTimeString = `${seconds}s`;
        }
    }

    stopTimer() {
        console.info("La synthèse s'est terminée en : ", this.elapsedTime, 'secondes');
        clearInterval(this.interval);
        this.isDataProcessing = false;
        this.dataProcessingTimeLeft = 0;

        if (document.hidden) {
            // Démarre le clignotement du titre si l'onglet est en arrière-plan
            this.startBlinkingTitle('(1) Votre synthèse Mercure est prête', 'Mercure');
            // Lance la notification si l'onglet est en arrière-plan
            this.sendNotification('Synthèse Mercure prête', "Votre synthèse est prête. Cliquez-ici pour voir les détails d'envoi de communications.");
        }
    }

    startBlinkingTitle(message: string, original: string) {
        let isOriginalTitle = true;
        // Si un clignotement est déjà en cours, ne rien faire
        if (this.blinkingTitleInterval) {
            return;
        }

        this.blinkingTitleInterval = setInterval(() => {
            // Alterne entre le message et le titre original chaque seconde
            document.title = isOriginalTitle ? message : original;
            isOriginalTitle = !isOriginalTitle;
        }, 1000);
    }

    stopBlinkingTitle() {
        // Arrête le clignotement et restaure le titre original
        clearInterval(this.blinkingTitleInterval);
        this.blinkingTitleInterval = null;
        document.title = this.originalTitle;
    }

    sendNotification(title: string, message: string) {
        // Vérifie si la permission a été accordée par l'utilisateur
        if (Notification.permission === 'granted') {
            // Créer et afficher la notification
            let notification = new Notification(title, {
                body: message,
                icon: 'assets/img/Mercure_Logo-2.png',
            });

            // Ajouter un écouteur pour le clic sur la notification
            notification.onclick = (ev) => {
                ev.preventDefault(); // empêche le navigateur de se focaliser sur l'onglet de la notification
                window.focus(); // amène la fenêtre contenant l'onglet au premier plan
                this.stopBlinkingTitle(); // Arrête le clignotement lorsque la notification est cliquée
            };
        }
    }

    getSupport() {
        return [this.com.support1, this.com.support2, this.com.support3].join('  ');
    }

    getTypeDest(): string {
        let types = [];
        if (this.com.selectedRecipientTypes.abonne) {
            types.push(this.recipientLabels.abonne);
        }
        if (this.com.selectedRecipientTypes.destinataire) {
            types.push(this.recipientLabels.destinataire);
        }
        return types.join(', ');
    }

    isBothRecipients() {
        return this.com.selectedRecipientTypes.abonne && this.com.selectedRecipientTypes.destinataire;
    }

    getTypeSelect() {
        let label = '';
        if (this.com.typeSelected == 'direct') label = 'Envoi direct';
        if (this.com.typeSelected == 'difer') label += ' Envoi différé';
        return label;
    }

    print() {
        window.print();
    }

    previous() {
        const previousSettings = {
            isSecondFieldActive: this.com.isSecondFieldActive,
            isThirdFieldActive: this.com.isThirdFieldActive,
            records: this.com.records,
            selectedIndex: this.isHorsIris ? 1 : 0,
            headers: this.com.headers,
            selectedRegion: this.com.selectedRegion,
            selectedTerritoire: this.com.selectedTerritoire,
            displayedNameColumns: this.com.displayedNameColumns,
        };
        this.previousStep.emit(previousSettings);
    }

    submit() {
        this.awsService.vaidateLot(this.com.idlot).subscribe(
            (res) => {
                const redirectUrl = environment.lienHermesMesComm;
                window.open(redirectUrl, '_blank');
            },
            (err) => {}
        );
    }

    initReport(val) {
        if (!this.data[val]) return;
        this.report[val] = { total: 0, items: [] };
        Object.keys(this.data[val]).forEach((key) => {
            this.report[val].total += 1;
            this.report[val].items.push({ key, value: this.data[val][key] });
        });
        if (this.report[val].items && this.report[val].items.length > 0)
            this.report[val].items = this.report[val].items.sort((el1, el2) => {
                return el2.value - el1.value;
            });
    }

    synthese() {
        this.reload();
    }

    public checkIfAdmin(): void {
        this.userLoginService.getHabilitations(this.userLoginService.getProfile().user.email).then((data) => {
            data[0].groupes.forEach((groupe) => {
                if (groupe === MercureRoles.MERCURE_DEMANDEUR) {
                    this.isMercureDemandeur = true;
                }
            });
        });
    }

    // Route Get Informations
    reload() {
        this.awsService.synthese(this.com.idlot).subscribe(
            (res) => {
                this.data = res.information;
                this.loading = false;
                this.initReport('communes');
                this.initReport('contrats');
                this.initReport('marques');
                if (this.isMercureDemandeur && res.information.mercure_validation) {
                    this.displayWarning = true;
                }
                this.total = this.data.nb_sms + this.data.nb_mail + this.data.nb_courrier_fr + this.data.nb_courrier_etr;
                this.supports = [this.getDataSupport(this.com.support1)];
                if (this.com.support2) this.supports.push(this.getDataSupport(this.com.support2));
                if (this.com.support3) this.supports.push(this.getDataSupport(this.com.support3));
                // Route Get Errors
                this.awsService.getReport(this.com.idlot).subscribe(
                    (res) => {
                        this.stopTimer();
                    },
                    (err) => {
                        if (err.status >= 500 && err.status < 600) {
                            this.utilsService.errorNotification(
                                'Une erreur est survenue, merci de contacter ' +
                                    '<a href="mailto:fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com" target="_blank" class="toastr-link"><b>' +
                                    'fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com' +
                                    '</b></a>' +
                                    ' pour plus d’informations.<br>ID à fournir : ' +
                                    '<b>' +
                                    this.idlot +
                                    '</b>',
                                'Erreur ' + err.status
                            );
                            clearInterval(this.interval);
                            this.isDataProcessing = false;
                            this.dataProcessingTimeLeft = 0;
                            this.previous();
                            return;
                        }
                    }
                );
            },
            (err) => {
                console.error(err);
                if (err.status >= 500 && err.status < 600) {
                    this.utilsService.errorNotification(
                        'Une erreur est survenue, merci de contacter ' +
                            '<a href="mailto:fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com" target="_blank" class="toastr-link"><b>' +
                            'fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com' +
                            '</b></a>' +
                            ' pour plus d’informations.<br>ID à fournir : ' +
                            '<b>' +
                            this.idlot +
                            '</b>',
                        'Erreur ' + err.status
                    );
                    clearInterval(this.interval);
                    this.isDataProcessing = false;
                    this.dataProcessingTimeLeft = 0;
                    this.previous();
                    return;
                }
                // Si la synthèse n'est pas terminée, on relance la requête
                if (this.elapsedTime < 15) {
                    setTimeout(() => {
                        this.reload();
                    }, 14000);
                    // On relance la requête toutes les 5 secondes entre 15secondes et 2 minutes
                } else if (this.elapsedTime >= 15 && this.elapsedTime < 120) {
                    setTimeout(() => {
                        this.reload();
                    }, 5000);
                    // On relance la requête toutes les 30 secondes entre 2 minutes et 10 minutes
                } else if (this.elapsedTime >= 120 && this.elapsedTime < 600) {
                    setTimeout(() => {
                        this.reload();
                    }, 30000);
                    // On relance la requête toutes les 1 minutes entre 10 minutes et 20 minutes
                } else if (this.elapsedTime >= 600 && this.elapsedTime < 1200) {
                    setTimeout(() => {
                        this.reload();
                    }, 60000);
                    // On arrête le timer et on affiche une erreur au bout de 20 minutes
                } else if (this.elapsedTime >= 1200) {
                    console.warn("La requête a expirée, aucune donnée n'a été retournée en : ", this.elapsedTime + 's');
                    this.utilsService.errorNotification('Une erreur est survenue lors de la récupération des données de synthèse. Veuillez réessayer ultérieurement.');
                }
            }
        );
    }

    percentage(percent) {
        return ((percent * 100) / this.total).toFixed(2);
    }

    downloadFile() {
        this.awsService.getReport(this.com.idlot).subscribe((res) => {
            window.open(res.url);
        });
    }

    getDataSupport(key) {
        switch (key) {
            case 'C':
                return {
                    label: 'Courier',
                    data: this.data.nb_courrier_fr + this.data.nb_courrier_etr,
                    fr: this.data.nb_courrier_fr,
                    etr: this.data.nb_courrier_etr,
                };
            case 'S':
                return {
                    label: 'SMS',
                    data: this.data.nb_sms,
                };
            case 'M':
                return {
                    label: 'Emails',
                    data: this.data.nb_mail,
                };
        }
    }
}
