<mat-spinner *ngIf="loading" class="connexion-loading"></mat-spinner>
<div class="page-message">
  <div class="left-block">
    <div>
      <h1 class="first-h1">Bienvenue sur</h1>
      <h1 class="second-h1">Mercure&nbsp;!</h1>
    </div>
    <span style="max-width: 30vw;">{{messageError ? "Vous n'êtes pas habilité" : "Vous n'êtes pas connecté."}}<span
        *ngIf="!messageError"> Afin
        d'accéder à l'application, veuillez vous authentifier.</span></span>
    <span *ngIf="messageError">{{messageError}}.</span>

    <button (click)="login()" class="login-btn" mat-raised-button>Je me connecte</button>
  </div>
  <div class="right-block" *ngIf="!messageError">
    <img src="assets/img/not_authenticated.svg" alt="">
  </div>
  <div class="right-block" *ngIf="messageError">
    <img src="assets/img/not_habilitated.svg" alt="">
  </div>
</div>