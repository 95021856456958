import { Storage } from 'aws-amplify';
import { Router } from '@angular/router';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';

import { filters } from 'src/app/data/filters';
import { useCase } from 'src/app/data/useCase';
import { UtilsService } from 'src/app/services/utils.service';
import { UserLoginService } from 'src/app/services/user-login.service';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-configuration-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
    filters = filters;
    useCase = useCase;
    selectedTheme: any;
    selectedCase: any;
    step = 1;
    selectedCaseFilters: any;
    public apiResponse = {
        message: '',
        color: '',
        textColor: '',
    };
    public fileName = 'message.json';
    public formattedMessage: string = '';
    public isHorsIris: boolean = false;
    public selectedIndex: number = 0;
    public displayedNameColumns = [];
    public headers = [];
    public activateSecondField: boolean = false;
    public activateThirdField: boolean = false;
    public selectedRegion: string = '';
    public selectedTerritoire: string = '';
    public displayCodeContratWarning: boolean = false;
    public drive = {
        link: '',
        sheet: '',
    };
    public infoColor = '##003297';
    public warningColor = '#b17f09';
    public alertColor = '#b10909';
    records: any[] = [];
    com = {
        date: new Date(new Date().getTime()),
        time: '8:00',
    };
    public missingCanaux = [];
    public retryLoadMessageCount = 0;

    constructor(private router: Router, private userLogin: UserLoginService, private utilsService: UtilsService, private renderer: Renderer2, @Inject(DOCUMENT) private document: Document) {}

    ngOnInit(): void {
        this.userLogin.isAuthenticated(this);
        setTimeout(() => {
            this.loadMessage();
        }, 100);
    }

    updateTriangleColor(color: string) {
        const styleElement = this.renderer.createElement('style');
        styleElement.textContent = `
        .triangle::before {
            border-color: ${color} transparent transparent transparent;
        }
    `;
        this.renderer.appendChild(this.document.head, styleElement);
    }

    public async loadMessage() {
        Storage.get(this.fileName, { download: true, expires: 10, cacheControl: 'no-cache' })
            .then((result) => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    this.apiResponse = JSON.parse(event.target.result);
                    this.formattedMessage = this.apiResponse.message?.replace(/\n/g, '<br>');
                    this.updateTriangleColor(this.apiResponse.color);
                };
                reader.readAsText((result as any).Body);
            })
            .catch((err) => {
                if (this.retryLoadMessageCount < 5) {
                    this.retryLoadMessageCount++;
                    setTimeout(() => {
                        this.loadMessage();
                    }, 500);
                } else {
                    this.utilsService.errorNotification("Les messages d'accueil n'ont pas pu être récupérés. Veuillez recharger la page.", err);
                    console.error("Erreur de récupération du message d'accueil: ", err);
                }
            });
    }

    isLoggedIn(message: string, isLoggedIn: boolean) {
        if (isLoggedIn) {
            this.router.navigate(['/configuration']);
        }
    }

    public saveMissingCanaux(event: any) {
        this.missingCanaux = event;
    }

    next(event, step?: number) {
        if (step === 1) {
            const horsIrisStep = (event || {}).horsIris || false;
            this.isHorsIris = horsIrisStep;
        }
        if (event.displayedNameColumns) {
            this.displayedNameColumns = event.displayedNameColumns;
        }
        if (event.drive) {
            this.drive = event.drive;
        }
        this.headers = event.headers;
        if (event.selectedRegion) {
            this.selectedRegion = event.selectedRegion;
            this.selectedTerritoire = event.selectedTerritoire;
        }
        this.displayCodeContratWarning = event.displayCodeContratWarning || false;
        this.selectedIndex = event.horsIris ? 1 : 0;
        this.com = { ...this.com, ...event };
        this.step = this.step + 1;
    }

    previous(records?: any) {
        this.step = this.step - 1;
        if (records) {
            if (records.selectedIndex) {
                this.selectedIndex = records.selectedIndex;
            }
            if (records.isSecondFieldActive) {
                this.activateSecondField = records.isSecondFieldActive;
            }
            if (records.isThirdFieldActive) {
                this.activateThirdField = records.isThirdFieldActive;
            }
            if (records.displayedNameColumns) {
                this.displayedNameColumns = records.displayedNameColumns;
            }
            if (records.headers) {
                this.headers = records.headers;
            }
            if (records.displayCodeContratWarning) {
                this.displayCodeContratWarning = records.displayCodeContratWarning;
            }
            if (records.drive) {
                this.drive = records.drive;
            }
            this.records = records.records;
        }
    }

    updateCase(value) {
        this.selectedCase = value;
        if (value) {
            this.selectedCaseFilters = [...filters.generalUseCase, ...filters[value]];
        }
    }
}
