
export const columns = {
    relaunch: [
        {key: 'num_abn', label: 'Numero Abonnement'},
        {key: 'code_fac', label: 'Code Facturation'},
        {key: 'dt_em', label: 'Date Emission Facture'},
        {key: 'mnt_ttc', label: 'Montant Solde TTC'},
        {key: 'st_rel', label: 'Stade Relance'},
        {key: 'code_tr', label: 'Code Territoire'},
        {key: 'code_ctr', label: 'Code Contrat'},
        {key: 'adr_branch', label: 'Adresse Branchement'},
        {key: 'code_act', label: 'Code Activité'},
    ],
    replace: [
        {key: 'num_abn', label: 'Numero Abonnement'},
        {key: 'code_fac', label: 'Code Facturation'}
    ]
};

