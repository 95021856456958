import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { EditorModule } from '@tinymce/tinymce-angular';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StepsComponent } from '../modules/configuration/components/steps/steps.component';
import { SanitizeHtmlPipe } from '../modules/support/pages/help-and-support/sanitizer.pipe';

@NgModule({
    imports: [CommonModule, BrowserModule, HttpClientModule, BrowserAnimationsModule, FormsModule, ReactiveFormsModule, AgGridModule.withComponents([]), EditorModule],
    exports: [BrowserModule, HttpClientModule, FormsModule, SanitizeHtmlPipe, CommonModule, BrowserAnimationsModule, StepsComponent, ReactiveFormsModule, EditorModule, AgGridModule],
    declarations: [StepsComponent, SanitizeHtmlPipe],
    providers: [SanitizeHtmlPipe],
})
export class SharedModule {}
