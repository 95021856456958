import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import {take} from 'rxjs/operators';
import { UtilsService } from 'src/app/services/utils.service';
import { Storage } from 'aws-amplify';

@Component({
  selector: 'app-carrousel',
  templateUrl: './carrousel.component.html',
  styleUrls: ['./carrousel.component.scss']
})
export class CarrouselComponent implements OnInit {

  public loading = false;
  public fileName: string = 'carrousel.json';
  public displayedSentences: string[] = [];
  
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(private utilsService: UtilsService, private _ngZone: NgZone) { }

  ngOnInit(): void {
    this.loadContent();
  }

  async setJsonFileOnS3(): Promise<void> {
    this.loading = true;
    try {
      await Storage.put(this.fileName, JSON.stringify({sentences : this.displayedSentences}));
    } catch (error) {
      this.loading = false;
      console.error(error);
    } finally  {
      this.loadContent();
    }
  }

  async loadContent(): Promise<void> {
    this.loading = true;
      try {
        const response = await Storage.get(this.fileName, { download: true, expires: 10, cacheControl: 'no-cache' });
        const reader = new FileReader();
        reader.onload = (event: any) => {
          const jsonContent = JSON.parse(event.target.result);
          this.displayedSentences = jsonContent.sentences;
        }
        reader.readAsText((response as any).Body);
        this.loading = false;
        this.utilsService.successNotification('Les données ont été récupérées avec succès.');
      } catch (error) {
        if(error.name === "NoSuchKey") {
          this.setJsonFileOnS3();
        }
        this.loading = false;
        this.utilsService.errorNotification('La récupération des données a échouée.');
      } finally {
        this.loading = false;
      }
  }

  async updateCarrouselMessages(): Promise<void> {
    this.loading = true;
    try {
      await Storage.put(this.fileName, JSON.stringify({sentences : this.displayedSentences}));
      this.utilsService.successNotification('Le nouveau message a été enregistré avec succès.');
      this.loadContent();
    } catch (error) {
      this.utilsService.errorNotification('La sauvegarde du message a échouée.', error);
      console.error(error);
    } finally {
      this.loading = false;
    }
  }

  addMessage(message: string): void {
    if(message === '') {
      this.utilsService.errorNotification('Un champ ne peut être ajouté vide.')
    } else {
      this.displayedSentences.push(message);
      this.updateCarrouselMessages();
      const textArea = document.getElementById('addMessage') as HTMLTextAreaElement;
      textArea.value = "";
    }
  }

  updateMessage(message: string, index: number): void {
    if(message === '') {
      this.utilsService.errorNotification('La sauvegarde a échoué, car un champ ne peut être vide !');
    } else {
      this.displayedSentences[index] = message;
      this.updateCarrouselMessages();    
    }
  }

  deleteMessage(index: number) : void {
    this.displayedSentences.splice(index, 1);
    this.updateCarrouselMessages();
  }

  triggerResize() {
    this._ngZone.onStable.pipe(take(1)).subscribe(() => this.autosize.resizeToFitContent(true));
  }

}
