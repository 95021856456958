import { Storage } from 'aws-amplify';
import { DatePipe } from '@angular/common';
import { OnInit, Component, ViewEncapsulation, Inject, Injectable } from '@angular/core';

import { AppEnum } from 'src/app/enums/app.enum';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-help-and-support',
    templateUrl: './help-and-support.component.html',
    styleUrls: ['./help-and-support.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HelpAndSupportComponent implements OnInit {
    rowsLimit = AppEnum.ROWS_LIMIT;
    public htmlPageContent: any;

    lienModelExtraction: string = '';
    lienGuideUtilisateur: string = '';
    lienCatalogueService: string = '';
    lienCourrierEnMasse: string = '';
    lienModeleCommunications: string = '';
    publicChapterEmail: string = '';

    constructor(public datepipe: DatePipe, private utilsService: UtilsService) {}

    ngOnInit() {
        this.lienModelExtraction = environment.lienModelExtraction;
        this.lienGuideUtilisateur = environment.lienGuideUtilisateur;
        this.lienCatalogueService = environment.lienCatalogueService;
        this.publicChapterEmail = environment.publicChapterEmail;
        this.lienCourrierEnMasse = environment.lienCourrierEnMasse;
        this.lienModeleCommunications = environment.lienModeleCommunications;
        setTimeout(() => {
            this.loadContent();
        }, 1000);
    }

    public async loadContent() {
        const fileName = 'help-and-support-page.html';
        Storage.get(fileName, { download: true, expires: 10, cacheControl: 'no-cache' })
            .then((result) => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    this.utilsService.successNotification("Contenu de la page d'aide et de support récupéré avec succès.");
                    this.htmlPageContent = event.target.result;
                };
                reader.readAsText((result as any).Body);
            })
            .catch((err) => {
                this.utilsService.errorNotification("La récupération du contenu de la page d'aide et de support a échouée.", err);
                console.error('Erreur: ', err);
            });
    }
}
