<h1 mat-dialog-title>Confirmer la modification des prix</h1>
<div mat-dialog-content>
  <span>Voici les nouveaux prix qui entreront en vigueur si vous validez les modifications</span>
  <br><br>
  <div class="fields-container">
    <!-- Ligne 1: SMS et Mail -->
    <div class="line">

      <div *ngFor="let key of ['S', 'M']" class="modified-fields">
        <span><b>{{ formNameMapping[key] || key }}:</b></span>
        <br>
        <div class="modified-values">
          <span>{{ initialFormValues[key] }}€ </span>
          <ng-container *ngIf="changedFields[key]">
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.56651 0.239825C5.70046 0.0862651 5.88212 0 6.07152 0C6.26093 0 6.44258 0.0862651 6.57653 0.239825L9.79086 3.92583C9.92477 4.07944 10 4.28775 10 4.50495C10 4.72214 9.92477 4.93045 9.79086 5.08406L6.57653 8.77007C6.44181 8.91927 6.26138 9.00184 6.07409 8.99997C5.88681 8.9981 5.70765 8.91196 5.57521 8.76009C5.44278 8.60822 5.36766 8.40277 5.36603 8.188C5.3644 7.97324 5.4364 7.76633 5.56651 7.61184L7.50011 5.32406H0.714297C0.524853 5.32406 0.343169 5.23776 0.209213 5.08415C0.075256 4.93053 0 4.72219 0 4.50495C0 4.2877 0.075256 4.07936 0.209213 3.92575C0.343169 3.77213 0.524853 3.68583 0.714297 3.68583H7.50011L5.56651 1.39805C5.4326 1.24444 5.35738 1.03614 5.35738 0.818938C5.35738 0.601738 5.4326 0.393431 5.56651 0.239825Z"
                fill="#0D47A1" />
            </svg>
            <span style="font-weight: bold; color: #46b846;"> {{ changedFields[key].newValue }}€</span>
          </ng-container>
        </div>
      </div>
      <div class="modified-fields"></div>
    </div>
    <!-- Ligne 2: Courrier standard, Courrier en lettre suivie et Courrier à l'étranger -->
    <div class="line">
      <div *ngFor="let key of ['C_EGC', 'C_ELS', 'C_ETR']" class="modified-fields">
        <span><b>{{ formNameMapping[key] || key }}:</b></span>
        <br>
        <div class="modified-values">
          <span>{{ initialFormValues[key] }}€ </span>
          <ng-container *ngIf="changedFields[key]">
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.56651 0.239825C5.70046 0.0862651 5.88212 0 6.07152 0C6.26093 0 6.44258 0.0862651 6.57653 0.239825L9.79086 3.92583C9.92477 4.07944 10 4.28775 10 4.50495C10 4.72214 9.92477 4.93045 9.79086 5.08406L6.57653 8.77007C6.44181 8.91927 6.26138 9.00184 6.07409 8.99997C5.88681 8.9981 5.70765 8.91196 5.57521 8.76009C5.44278 8.60822 5.36766 8.40277 5.36603 8.188C5.3644 7.97324 5.4364 7.76633 5.56651 7.61184L7.50011 5.32406H0.714297C0.524853 5.32406 0.343169 5.23776 0.209213 5.08415C0.075256 4.93053 0 4.72219 0 4.50495C0 4.2877 0.075256 4.07936 0.209213 3.92575C0.343169 3.77213 0.524853 3.68583 0.714297 3.68583H7.50011L5.56651 1.39805C5.4326 1.24444 5.35738 1.03614 5.35738 0.818938C5.35738 0.601738 5.4326 0.393431 5.56651 0.239825Z"
                fill="#0D47A1" />
            </svg>
            <span style="font-weight: bold; color: #46b846;"> {{ changedFields[key].newValue }}€</span>
          </ng-container>
        </div>
      </div>
    </div>
    <!-- Ligne 3: Courrier recommandé sans AR et Courrier recommandé avec AR -->
    <div class="line last">
      <div *ngFor="let key of ['C_RRS', 'C_RRA']" class="modified-fields">
        <span><b>{{ formNameMapping[key] || key }}:</b></span>
        <br>
        <div class="modified-values">
          <span>{{ initialFormValues[key] }}€ </span>
          <ng-container *ngIf="changedFields[key]">
            <svg width="10" height="9" viewBox="0 0 10 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.56651 0.239825C5.70046 0.0862651 5.88212 0 6.07152 0C6.26093 0 6.44258 0.0862651 6.57653 0.239825L9.79086 3.92583C9.92477 4.07944 10 4.28775 10 4.50495C10 4.72214 9.92477 4.93045 9.79086 5.08406L6.57653 8.77007C6.44181 8.91927 6.26138 9.00184 6.07409 8.99997C5.88681 8.9981 5.70765 8.91196 5.57521 8.76009C5.44278 8.60822 5.36766 8.40277 5.36603 8.188C5.3644 7.97324 5.4364 7.76633 5.56651 7.61184L7.50011 5.32406H0.714297C0.524853 5.32406 0.343169 5.23776 0.209213 5.08415C0.075256 4.93053 0 4.72219 0 4.50495C0 4.2877 0.075256 4.07936 0.209213 3.92575C0.343169 3.77213 0.524853 3.68583 0.714297 3.68583H7.50011L5.56651 1.39805C5.4326 1.24444 5.35738 1.03614 5.35738 0.818938C5.35738 0.601738 5.4326 0.393431 5.56651 0.239825Z"
                fill="#0D47A1" />
            </svg>
            <span style="font-weight: bold; color: #46b846;"> {{ changedFields[key].newValue }}€</span>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close class="cancel-btn">Annuler</button>
    <button mat-button (click)="saveChanges()" class="primary-btn">Sauvegarder les modifications</button>
  </div>