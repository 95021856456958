<div>
  <header>
    <mat-toolbar color="accent" class="body">
      <a routerLink="/admin/messages" routerLinkActive="active" class="sub-header-btn no-underline">
        <button mat-button class="header-btn">
          Gestion des messages
          <mat-icon aria-hidden="false" class="icon-menu">message</mat-icon>
        </button>
      </a>
      <a routerLink="/admin/prices" routerLinkActive="active" class="sub-header-btn no-underline">
        <button mat-button class="header-btn">
          Modification des prix
          <mat-icon aria-hidden="false" class="icon-menu">euro</mat-icon>
        </button>
      </a>
      <a routerLink="/admin/variables" routerLinkActive="active" class="sub-header-btn no-underline">
        <button mat-button class="header-btn">
          Nom de variables
          <mat-icon aria-hidden="false" class="icon-menu">text_fields</mat-icon>
        </button>
      </a>
      <a routerLink="/admin/carrousel" routerLinkActive="active" class="sub-header-btn no-underline">
        <button mat-button class="header-btn">
          Carrousel
          <mat-icon aria-hidden="false" class="icon-menu">view_carousel</mat-icon>
        </button>
      </a>
      <a routerLink="/admin/help-and-support" routerLinkActive="active" class="sub-header-btn no-underline">
        <button mat-button class="header-btn">
          Page d'aide et support
          <mat-icon aria-hidden="false" class="icon-menu">help</mat-icon>
        </button>
      </a>
    </mat-toolbar>
  </header>
  <router-outlet></router-outlet>
</div>