import {Component, Input, OnInit} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {

  @Input() form: any[];

    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes = [ENTER, COMMA] as const;
    fruits: any[] = [];
    data = [];

    add(event: MatChipInputEvent, index): void {
        const value = (event.value || '').trim();

        // Add our fruit
        if (value) {
            if (!this.data[index]) {
                this.data[index] = [];
            }
            this.data[index].push({name: value});
        }

    }

    remove(fruit: any, i): void {
        const index = this.data[i].indexOf(fruit);

        if (index >= 0) {
            this.data[i].splice(index, 1);
        }
    }

    getItems(index) {
      return this.data[index]
    }

    isFilterEmpty() {
      return true;
    }

    ngOnInit() {}

}
