import * as XLSX from 'xlsx';
import { Storage } from 'aws-amplify';
import { DatePipe } from '@angular/common';
import { columns } from 'src/app/data/columns';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Component, AfterViewInit, AfterViewChecked, ChangeDetectorRef, ViewChild, TemplateRef, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

import { AppEnum } from 'src/app/enums/app.enum';
import { AwsService } from 'src/app/services/aws.service';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';
import { SettingsSendComponent } from '../settings-send/settings-send.component';
import { ConfirmSameTableComponent } from './confirm-same-table/confirm-same-table.component';

declare var gapi: any;
declare var google: any;
let tokenClient: any;
let gapiInited = false;
let gisInited = false;

export interface Destinataire {
    numero_abonnement: string;
    code_facturation: string;
    date_facture: string;
    solde_total_ttc: string;
    stade_relance: string;
    code_territoire: string;
    code_contrat: string;
    position: number;
}

export class CsvData {
    public id: any;
    public min: any;
    public max: any;
    public score: any;
}

@Component({
    selector: 'app-recipients',
    templateUrl: './recipients.component.html',
    styleUrls: ['./recipients.component.scss'],
})
export class RecipientsComponent implements OnInit, AfterViewInit, AfterViewChecked {
    name = 'Angular';
    rowsLimit = AppEnum.ROWS_LIMIT;
    pageSize = AppEnum.PAGE_SIZE;
    @Input() headers = [];
    @Input() selectedCase: any;
    @Input() public selectedIndex: number = 0;
    @Input() public records: any[];
    @Input() displayedNameColumns = [];

    @Output() nextStep = new EventEmitter<any>();
    @Output() isHorsIris = new EventEmitter<boolean>();
    @Output() previousStep = new EventEmitter<string>();
    @Output() missingCanaux = new EventEmitter<any>();

    @ViewChild('csvReader') csvReader: any;
    @ViewChild('block1', { read: TemplateRef }) Block1: TemplateRef<any>;
    @ViewChild('block2', { read: TemplateRef }) Block2: TemplateRef<any>;
    @ViewChild('block3', { read: TemplateRef }) Block3: TemplateRef<any>;
    @ViewChild('block4', { read: TemplateRef }) Block4: TemplateRef<any>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('settingsRef', { static: false }) settingComponent: SettingsSendComponent;
    @ViewChild('fileImportInput', { static: false }) fileImportInput: any;

    jsondatadisplay: any;
    value: number = 0;
    publicChapterEmail: string = '';
    lienCatalogueService: string = '';
    lienCourrierEnMasse: string = '';
    filename = '';
    displayedColumns: string[] = ['numero_abonnement', 'code_facturation', 'code_activite', 'date_facture', 'solde_total_ttc', 'stade_relance', 'code_territoire', 'code_contrat', 'adresse_branchement'];
    labelColumns = {
        numero_abonnement: 'numero abonnement',
        code_facturation: 'code facturation',
        date_facture: 'date emmission facture',
        solde_total_ttc: 'montant solde ttc',
        stade_relance: 'stade relance',
        code_territoire: 'code territoire',
        code_contrat: 'code contrat',
        adresse_branchement: 'adresse branchement',
        code_activite: 'code_activite',
    };
    headersRow = [];
    errors = [];
    dataSource = new MatTableDataSource<any>();
    selection = new SelectionModel<Destinataire>(true, []);
    columns = columns;
    public com = {
        date: new Date(new Date().getTime()),
        time: '8:00',
    };
    public loading: boolean = false;
    public missingCanal = [];
    public displayWarning: boolean = false;
    public fileName = 'variables.json';
    lines = [];
    link = '';
    sheet = '';
    @Input() public displayCodeContratWarning: boolean = false;
    @Input() drive = {
        link: '',
        sheet: '',
    };
    displaySheetBloc = false;
    public tabList = [];
    public retryLoadVariables = 0;
    public retryLoadGoogleSheet = 0;
    public authorizedDisplayTab: number = 0;
    public authorizedVariables: string[] = [];

    constructor(public datepipe: DatePipe, private awsService: AwsService, public dialog: MatDialog, private utilsService: UtilsService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        if (this.records.length > 0) {
            this.authorizedDisplayTab = this.selectedIndex;
            this.dataSource = new MatTableDataSource<Destinataire>(this.records);
            this.displayWarning = true;
        }
        setTimeout(() => {
            this.loadVariables();
        }, 300);

        this.publicChapterEmail = environment.publicChapterEmail;
        this.lienCatalogueService = environment.lienCatalogueService;
        this.lienCourrierEnMasse = environment.lienCourrierEnMasse;
        gapi.load('client', () => {
            this.initializeGapiClient(this);
        });
        this.gisLoaded();
    }

    ngAfterViewInit() {
        this.initializeTabs();
    }

    ngAfterViewChecked() {
        this.cdr.detectChanges();
    }

    initializeTabs() {
        this.tabList = [
            { title: 'Envoi lots IRIS', tabNumber: 1, routerLink: '/configuration', active: true, content: this.Block1 },
            { title: 'Envoi des lots Hors IRIS', tabNumber: 2, routerLink: '/configuration-hors-iris', active: false, content: this.Block1 },
            { title: 'Envoi via le catalogue de service', tabNumber: 3, link: this.lienCatalogueService, active: false, content: this.Block3 },
            { title: 'Envoi des courriers en masse', tabNumber: 4, link: this.lienCourrierEnMasse, active: false, content: this.Block4 },
        ];
        if (this.paginator) {
            this.dataSource.paginator = this.paginator;
        }
    }

    public async loadVariables() {
        this.loading = true;
        Storage.get(this.fileName, { download: true, expires: 10, cacheControl: 'no-cache' })
            .then((result) => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    this.authorizedVariables = JSON.parse(event.target.result).variables;
                };
                reader.readAsText((result as any).Body);
                this.loading = false;
            })
            .catch((err) => {
                if (this.retryLoadVariables < 4) {
                    this.retryLoadVariables++;
                    setTimeout(() => {
                        this.loadVariables();
                    }, 500);
                } else {
                    console.error('Erreur: ', err);
                    this.utilsService.errorNotification('La récupération des variables autorisées par Mercure a échouée. Veuillez recharger la page.', err);
                    this.loading = false;
                }
            });
    }

    next(selectedIndex: number) {
        if (this.displayWarning === false) {
            this.nextStep.emit({
                records: this.records,
                displayedNameColumns: this.displayedNameColumns,
                horsIris: selectedIndex === 1 ? true : false,
                headers: this.headers,
                drive: this.drive || '',
                displayCodeContratWarning: this.displayCodeContratWarning,
            });
        } else {
            const dialogRef = this.dialog.open(ConfirmSameTableComponent, {
                /* data: {
                    changedFields: this.changedFields,
                    formNameMapping: this.formNameMapping,
                    initialFormValues: this.initialFormValues,
                }, */
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result?.event === 'validateWarning') {
                    this.nextStep.emit({
                        records: this.records,
                        displayedNameColumns: this.displayedNameColumns,
                        horsIris: selectedIndex === 1 ? true : false,
                        headers: this.headers,
                        displayCodeContratWarning: this.displayCodeContratWarning,
                    });
                }
            });
        }
    }

    resetFile() {
        this.loading = true;
        this.filename = '';
        this.records = [];
        this.errors = [];
        this.displayWarning = false;
        this.dataSource = new MatTableDataSource<Destinataire>();
    }

    public scrollToBottom() {
        //wait 200ms for the data to be loaded :
        setTimeout(() => {
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
        }, 200);
    }

    // Fonction qui permet de lire le fichier importé localement
    onFileChange(ev) {
        if (this.selectedIndex === 0) {
            this.authorizedDisplayTab = 0;
        } else {
            this.authorizedDisplayTab = 1;
        }
        let workBook = null;
        let jsonData = null;
        this.headers = []; //['#'];
        this.drive = {
            link: '',
            sheet: '',
        };
        this.displayWarning = false;
        const reader = new FileReader();
        const file = ev.target.files[0];
        reader.onload = (event) => {
            const data = reader.result;
            if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') workBook = XLSX.read(data, { type: 'binary', raw: true });
            else workBook = XLSX.read(data, { type: 'string', raw: true });
            jsonData = workBook.SheetNames.reduce((initial, name) => {
                const sheet = workBook.Sheets[name];
                var range = XLSX.utils.decode_range(sheet['!ref']);
                var C,
                    R = range.s.r;
                /* start in the first row */
                /* walk every column in the range */
                for (C = range.s.c; C <= range.e.c; ++C) {
                    var cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
                    /* find the cell in the first row */

                    var hdr = 'UNKNOWN ' + C; // <-- replace with your desired default
                    if (cell && cell.t) {
                        hdr = XLSX.utils.format_cell(cell);
                    }
                    this.headers.push(hdr);
                }
                initial[name] = XLSX.utils.sheet_to_json(sheet, { raw: false });
                return initial;
            }, {});
            const lines: any = Object.values(jsonData)[0];
            if (this.selectedIndex === 0) {
                this.displayedNameColumns = this.authorizedVariables;
            }
            if (this.selectedIndex === 1) {
                this.displayedNameColumns = this.authorizedVariables;
                this.displayedNameColumns.push('email_destinataire', 'telephone_destinataire', 'afnor1', 'afnor2', 'afnor3', 'afnor4', 'afnor5', 'afnor6', 'afnor7');
            }
            this.displayedNameColumns = this.findCommonColumnsFromImportedFile(this.headers);
            this.records = lines;
            this.awsService.storeCsvData(lines, false);
            if (this.checkHeadersErrors(this.headers)) {
                this.checkErrors();
                this.dataSource = new MatTableDataSource<Destinataire>(this.records);
            }
        };
        if (file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsText(file);
        }
        this.loading = false;
        this.scrollToBottom();
    }

    // Fonction qui renvoie la liste des colonnes "autorisées" (présentes dans le fichier et dans la liste des colonnes autorisées)
    private findCommonColumnsFromImportedFile(data: any[]): string[] {
        if (!data || data.length === 0) {
            return [];
        }
        const columnsInData = data;
        return columnsInData.filter((column) => this.displayedNameColumns.includes(column));
    }

    private findCommonColumnsFromGoogleDrive(data: any[]): string[] {
        if (!data || data.length === 0) {
            return [];
        }
        const columnsInData = data[0];
        return columnsInData.filter((column) => this.displayedNameColumns.includes(column));
    }

    checkHeadersErrors(headersRow) {
        this.errors = [];
        /* Lot IRIS */
        if (this.selectedIndex === 0) {
            if (headersRow.indexOf('numero_abonnement') !== -1 && headersRow.indexOf('code_contrat') !== -1) {
                return true;
            } else {
                if (headersRow.indexOf('numero_abonnement') === -1) {
                    this.errors.push('Le champ <b>“numero_abonnement”</b> est manquant');
                    return false;
                }
                if (headersRow.indexOf('code_contrat') === -1) {
                    this.errors.push('Le champ <b>“code_contrat”</b> est manquant');
                    return false;
                }
            }
        }
        /* Lot Hors IRIS */
        if (this.selectedIndex === 1) {
            if (
                headersRow.indexOf('email_destinataire') !== -1 ||
                headersRow.indexOf('telephone_destinataire') !== -1 ||
                (headersRow.indexOf('afnor1') !== -1 && headersRow.indexOf('afnor4') !== -1 && headersRow.indexOf('afnor6') !== -1 && headersRow.indexOf('afnor7') !== -1)
            ) {
                if (headersRow.indexOf('email_destinataire') === -1) {
                    this.missingCanal.push('email_destinataire');
                }
                if (headersRow.indexOf('telephone_destinataire') === -1) {
                    this.missingCanal.push('telephone_destinataire');
                }
                if (headersRow.indexOf('afnor1') === -1 || headersRow.indexOf('afnor4') === -1 || headersRow.indexOf('afnor6') === -1 || headersRow.indexOf('afnor7') === -1) {
                    this.missingCanal.push('afnor1, afnor4, afnor6, afnor7');
                }
                if (headersRow.indexOf('code_contrat') === -1) {
                    if (headersRow.indexOf('code_instance') === -1 || headersRow.indexOf('code_traite_abonnement') === -1 || headersRow.indexOf('code_commune_abonnement') === -1) {
                        this.errors.push(
                            'Vous devez fournir un fichier contenant la colonne <b>“code_contrat”</b> ou bien les colonnes <b>“code_instance”</b>, <b>“code_traite_abonnement”</b> et <b>“code_commune_abonnement”</b>'
                        );
                    }
                    this.displayCodeContratWarning = true;
                } else if (headersRow.indexOf('code_contrat') !== -1) {
                    this.displayCodeContratWarning = false;
                }
                this.missingCanaux.emit(this.missingCanal);
                return true;
            } else {
                this.errors.push(
                    'Vous avez demandé l’envoi hors IRIS. ' +
                        'Il faut joindre dans votre fichier les données de contacts:' +
                        '<ul style="text-align: left !important;"><br><li>la variable <b>email_destinataire</b> (si vous souhaitez <b>envoyer des e-mail</b>)</li>' +
                        '<li>la variable <b>telephone_destinataire</b> (si vous souhaitez <b>envoyer des sms</b>)</li>' +
                        '<li>les variables <b>afnor1</b>, <b>afnor4</b>, <b>afnor6</b> et <b>afnor7</b> (si vous souhaitez <b>envoyer des courriers</b>)' +
                        '<br>(afnor 1 pour le nom du destinataire, afnor 4 pour le numéro et nom de la rue, afnor 6 pour le code postale et la ville, afnor 7 pour le Pays)</ul>'
                );
                return false;
            }
        }
        return true;
    }

    isValidTime(timeString) {
        // Regex to check valid
        // Traditional Time Formats (HH:MM:SS  or HH:MM)
        let regex = new RegExp(/^(?:[01]?[0-9]|2[0-3]):[0-5]?[0-9](?::[0-5]?[0-9])?$/);

        const isShort = (timeString || '').length < 6;

        // Return true if the str
        // matched the ReGex
        if (regex.test(timeString || '') == true) {
            return [true, isShort ? timeString + ':00' : timeString.slice(0, 8)];
        }
        return [false, timeString];
    }

    isValidDate(dateString) {
        if (dateString.length < 10) return [false, dateString];
        if (dateString.includes('-')) return [this.isValidHermesDate(dateString), dateString];
        return this.isValidMercureDate(dateString);
    }

    isValidMercureDate(dateString) {
        var regEx = /^\d{2}\/\d{2}\/\d{4}$/;
        if (!dateString.match(regEx)) return [false, dateString]; // Invalid format
        var newDateString = dateString.slice(6, 10) + '-' + dateString.slice(3, 5) + '-' + dateString.slice(0, 2);
        return [this.isValidHermesDate(newDateString), newDateString];
    }

    isValidHermesDate(dateString) {
        var regEx = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateString.match(regEx)) return false; // Invalid format
        var d = new Date(dateString);
        return d.toISOString().slice(0, 10) === dateString;
    }

    /**
     * Transform an integer into an alphabet string
     * @param value Integer
     * For exemple :
     *  1 = A
     *  2 = B
     *  ...
     *  26 = Z
     *  27 = AA
     *  28 = AB
     *  ...
     *  78 = BZ
     *  79 = CA
     *  80 = CB
     *
     *  https://stackoverflow.com/questions/36129721/convert-number-to-alphabet-letter
     *
     * Important: If value < 1 return an empty string ''
     *
     * To convert an integer x ( 1 <= x <= 26), simply do (x + 9).toString(36).toUpperCase()
     * This function, we should call it alphabet(x).
     * Everything works well when x in between 1 and 26, but what if integer is greater than 26 ?
     * This is the algorithm, I implement
     * x = value // 26 | quotient
     * y = value % 26 | rest of division
     * if y == 0:
     *      if x > 1:
     *          alphabet(x - 1) + 'Z'
     *      else:
     *          'Z'
     * elif x == 0:
     *      alphabet(y)
     * else:
     *      alphabet(x) + alphabet(y)
     */
    fromIntegerToAlphabet(value) {
        if (value < 1) return '';

        let quotient = Math.floor(value / 26);
        let restOfDivision = value % 26;
        if (restOfDivision == 0) {
            return `${quotient > 1 ? (quotient - 1 + 9).toString(36).toUpperCase() : ''}Z`;
        }
        const result = (restOfDivision + 9).toString(36).toUpperCase();
        if (quotient == 0) {
            return result;
        }
        return `${(quotient + 9).toString(36).toUpperCase()}${result}`;
    }

    validateField(index: number, fieldName: string, isValid: any, errorMessage: string, errorDescription: string) {
        if (index != -1) {
            let tempErrors = [];
            this.records.forEach((record, index) => {
                let fieldValue = record[fieldName];
                if (!isValid(fieldValue)) {
                    tempErrors.push({
                        value: fieldValue,
                        cell: `${this.fromIntegerToAlphabet(index + 1)}:${String(index + 2)}`,
                    });
                }
            });

            if (tempErrors.length > 0) {
                let errorDescriptionFormatted = tempErrors.map((e) => `"<b>${e.value}</b>" (cellule ${e.cell})`).join(', ');
                let isPlural = tempErrors.length > 1;
                let fullErrorMessage =
                    `${isPlural ? 'Les valeurs' : 'La valeur'} ${errorDescriptionFormatted} ${isPlural ? 'ne sont' : "n'est"} pas ${errorDescription}. <br>` +
                    `<span style="color: darkorange;">${errorMessage}</span> <br><br>`;
                this.errors.push(fullErrorMessage);
            }
        }
    }

    checkErrors() {
        const num_abon_index = this.headers.indexOf('numero_abonnement');
        const code_contrat_index = this.headers.indexOf('code_contrat');
        const stade_relance_index = this.headers.indexOf('stade_relance');
        const code_facturation_index = this.headers.indexOf('code_facturation');
        const date_facture_index = this.headers.indexOf('date_facture');
        const solde_total_index = this.headers.indexOf('solde_total_ttc');
        const code_territoire_index = this.headers.indexOf('code_territoire');
        const code_activite_index = this.headers.indexOf('code_activite');

        if (this.records.length > this.rowsLimit) {
            this.errors.push(
                `Mercure permet l’envoi de  ${this.rowsLimit} communications maximum dans un lot. Pour envoyer votre campagne de communications, merci de réduire le nombre de destinataires par envoi.`
            );
            return;
        }

        // Gestion d'erreurs de code_contrat (cellules vides)
        if (this.selectedIndex === 1) {
            let emptyCells = [];
            let allEmpty = true;

            for (let index = 0; index < this.records.length; index++) {
                let code_contrat = this.records[index].code_contrat;
                if (!code_contrat) {
                    /* Si la cellule de code contrat est vide */
                    emptyCells.push(`${this.fromIntegerToAlphabet(code_contrat_index + 1)}:${String(index + 2)}`);
                } else {
                    /* Si au moins une cellule est bien remplie */
                    allEmpty = false;
                    this.displayCodeContratWarning = false;
                }
            }

            if (emptyCells.length > 0 && !allEmpty) {
                let emptyCellsMessage = emptyCells.length === 1 ? `<b>${emptyCells[0]}</b> est vide` : '<b>' + emptyCells.join('</b> et <b>') + '</b> sont vides';

                this.errors.push(
                    emptyCellsMessage +
                        "<br><span style='color: darkorange;'>La colonne <b>'code_contrat'</b> de votre fichier contient à la fois des cellules remplies et vides. Veuillez remplir complètement cette colonne pour tous les destinataires ou la laisser totalement vide.</span>"
                );
                this.displayCodeContratWarning = false;
                return;
            } else if (allEmpty) {
                this.displayCodeContratWarning = true;
                /*                 return;
                 */
            }
        }

        const allDateFields = ['date_intervention', 'date_debut', 'date_fin', 'date_derniere_releve', 'date_debut_abonnement'];
        const allTimeFields = ['heure_debut', 'heure_fin'];
        const dateFields = [];
        const timeFields = [];
        allDateFields.forEach((dateField) => {
            if (this.headers.indexOf(dateField) != -1) {
                dateFields.push(dateField);
            }
        });
        allTimeFields.forEach((timeField) => {
            if (this.headers.indexOf(timeField) != -1) {
                timeFields.push(timeField);
            }
        });

        if (this.displayCodeContratWarning) {
            this.getInstanceTraiteCommuneColumnsError();
        }

        // Gestion d'erreurs de code_activite
        this.validateField(
            code_activite_index,
            'code_activite',
            (value) => value.match(/^[1-9]$/),
            'La variable <b>code_activite</b> doit contenir uniquement un chiffre entre 1 et 9.',
            "un code d'activité valide"
        );

        // Gestion d'erreurs de code_territoire
        this.validateField(
            code_territoire_index,
            'code_territoire',
            (value) => value.match(/^(?=[0-9A-Z]{2,3}$)[0-9]{1,3}[A-Z]{0,2}$/),
            'La variable <b>code_territoire</b> doit commencer par un chiffre et contenir entre 2 et 3 caractères au total (exemple : 64A, 2G, 640 ...etc)',
            'un code de territoire valide'
        );

        // Gestion d'erreurs de solde_total_ttc
        this.validateField(
            solde_total_index,
            'solde_total_ttc',
            (value) => value.match(/^[0-9]+([,.][0-9]{1,2})?$/),
            'La variable <b>solde_total_ttc</b> doit être un nombre à une ou deux décimales maximum (avec un point ou une virgule si le nombre a une décimale, par exemple 108 ou 108.75 ou 108,75)',
            'un nombre valide'
        );

        // Gestion d'erreurs de date_facture
        this.validateField(date_facture_index, 'date_facture', (value) => this.isValidMercureDate(value)[0], 'La variable <b>date_facture</b> doit être une date (format JJ/MM/AAAA)', 'une date valide');

        // Gestion d'erreurs de code_facturation
        this.validateField(
            code_facturation_index,
            'code_facturation',
            (value) => /^[0-9]+$/.test(value),
            'La variable <b>code_facturation</b> doit être une suite de chiffres (exemple : “20231048445440” et non du texte)',
            'un nombre valide'
        );

        // Gestion d'erreurs de stade_relance
        this.validateField(
            stade_relance_index,
            'stade_relance',
            (value) => ['10', '20', '30', '68', '75', '84', '91', '92', '93'].includes(value),
            'La variable <b>stade_relance</b> doit contenir uniquement un de ces numéros : 10, 20, 30, 68, 75, 84, 91, 92, 93',
            'un stade de relance valide'
        );

        // Vérifie si les colonnes numero_abonnement et code_contrat sont présentes ou si on est sur un lot HORS IRIS
        if ((num_abon_index != -1 && code_contrat_index != -1) || this.selectedIndex !== 0) {
            this.records.forEach((record, index) => {
                let num_abon = record['numero_abonnement'] + '';
                const code_contrat = record['code_contrat'];
                if (!num_abon && this.selectedIndex === 0) {
                    this.errors.push('Cellule “' + `${this.fromIntegerToAlphabet(num_abon_index + 1)}:${String(index + 2)}` + '”: est manquante');
                    return;
                }

                num_abon = num_abon?.replace(' ', '');
                if (num_abon === 'undefined') {
                    num_abon = '';
                }
                record['numero_abonnement'] = num_abon;
                if (num_abon.length == 15) {
                    num_abon = '0' + num_abon;
                    record['numero_abonnement'] = num_abon;
                }

                if (this.selectedIndex === 0) {
                    if ([7, 8].indexOf(num_abon.length) !== -1) {
                        record['code_instance'] = '96';
                    } else if (num_abon.length == 16) {
                        record['code_instance'] = num_abon[0] + num_abon[1];
                    } else {
                        this.errors.push(
                            'Cellule “' +
                                `${this.fromIntegerToAlphabet(num_abon_index + 1)}:${String(index + 2)}` +
                                '”: Le format du numero d’abonnement est invalide (7-8 caractères pour Waterp, 16 caractères pour GN)'
                        );
                        return;
                    }
                }

                // Check if date has valid values
                dateFields.forEach((dateField) => {
                    let dateValue = String(record[dateField]);
                    let checkDateValueResult = this.isValidDate(dateValue);
                    let dateFieldIndex = this.headers.indexOf(dateField);
                    if (checkDateValueResult[0]) {
                        record[dateField] = checkDateValueResult[1];
                    } else if (dateFieldIndex != -1) {
                        this.errors.push('Cellule "' + `${this.fromIntegerToAlphabet(dateFieldIndex + 1)}:${String(index + 2)}` + '": Format de date non valide (Formats acceptes: YYYY-MM-DD ou DD/MM/YYYY)');
                    } else {
                        this.errors.push('Colonne "' + dateField + '" ligne "' + String(index + 2) + '": Format de date non valide (Formats acceptes: YYYY-MM-DD ou DD/MM/YYYY)');
                    }
                });

                // Check if time has valid values
                timeFields.forEach((timeField) => {
                    let timeValue = String(record[timeField]);
                    let checkTimeValueResult = this.isValidTime(timeValue);
                    let timeFieldIndex = this.headers.indexOf(timeField);
                    if (checkTimeValueResult[0]) {
                        record[timeField] = checkTimeValueResult[1];
                    } else if (timeFieldIndex != -1) {
                        this.errors.push(`Cellule "${this.fromIntegerToAlphabet(timeFieldIndex + 1)}:${String(index + 2)}": Format d'heure non valide (Formats acceptes: HH:MM ou HH:MM:SS)`);
                    } else {
                        this.errors.push(`Colonne "${timeField}" ligne "${String(index + 2)}": Format d'heure non valide (Formats acceptes: HH:MM ou HH:MM:SS)`);
                    }
                });
            });
        } else {
            this.errors.push('Une des colonnes obligatoires est manquante: “numero_abonnement” et/ou “code_contrat”');
        }
    }

    public getInstanceTraiteCommuneColumnsError() {
        // Création d'un objet pour stocker les erreurs par type
        const errorGroups = {
            code_instance: [],
            code_traite_abonnement: [],
            code_commune_abonnement: [],
        };

        this.records.forEach((record, index) => {
            let instanceValue = record['code_instance'];
            let traiteAbonnementValue = record['code_traite_abonnement'];
            let communeValue = record['code_commune_abonnement'];

            if (!instanceValue) {
                errorGroups['code_instance'].push(index + 2);
            }
            if (!traiteAbonnementValue) {
                errorGroups['code_traite_abonnement'].push(index + 2);
            }
            if (!communeValue) {
                errorGroups['code_commune_abonnement'].push(index + 2);
            }
        });

        for (const [errorType, lines] of Object.entries(errorGroups)) {
            if (lines.length > 0) {
                let formattedLines = '';

                if (lines.length === 1) {
                    formattedLines = lines[0].toString();
                } else if (lines.length === 2) {
                    formattedLines = lines.join(' et ');
                } else {
                    const lastLine = lines.pop();
                    formattedLines = lines.join(', ') + ' et ' + lastLine; // Jointure des lignes par des virgules et "et" avant la dernière
                }

                this.errors.push(`La colonne <b>${errorType}</b> est vide à la ligne ${formattedLines}`);
            }
        }
    }

    getDataRecordsArrayFromCSVFile(csvRecordsArray: any, headers) {
        let csvArr = [];
        for (let i = 1; i < csvRecordsArray.length; i++) {
            let curruntRecord = csvRecordsArray[i].split(',');
            let csvRecord: CsvData = new CsvData();
            this.displayedColumns.forEach((elm) => {
                const index = headers.indexOf(elm);
                if (index >= 0) {
                    csvRecord[elm] = curruntRecord[index];
                }
            });
            csvArr.push(csvRecord);
        }
        return csvArr;
    }

    //check etension
    isValidCSVFile(file: any) {
        return true;
    }

    getHeaderArray(csvRecordsArr: any) {
        let headers = csvRecordsArr[0].split(',');
        let headerArray = [];
        for (let j = 0; j < headers.length; j++) {
            headerArray.push(headers[j]);
        }
        return headerArray;
    }

    fileReset() {
        this.csvReader.nativeElement.value = '';
        this.records = [];
        this.jsondatadisplay = '';
    }

    getJsonData() {
        this.jsondatadisplay = JSON.stringify(this.records);
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
            return;
        }

        this.selection.select(...this.dataSource.data);
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: Destinataire): string {
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selectedCase']) {
            this.displayedColumns = ['select'];
            columns[this.selectedCase].forEach((elm) => {
                this.displayedColumns.push(elm.key);
            });
        }
    }

    isReady() {
        return !this.records || this.records.length == 0 || this.errors.length > 0;
    }

    initializeGapiClient(vm) {
        gapi.client.init({
            apiKey: environment.apiKey,
            discoveryDocs: ['https://sheets.googleapis.com/$discovery/rest?version=v4'],
        });
        gapiInited = true;
        if (gisInited) {
            vm.displaySheetBloc = true;
        }
    }

    gisLoaded() {
        if (typeof google !== 'undefined') {
            tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: environment.googleClientId,
                scope: 'https://www.googleapis.com/auth/spreadsheets.readonly',
                callback: '', // defined later
            });
            gisInited = true;
            if (gapiInited) {
                this.displaySheetBloc = true;
            }
        } else {
            if (this.retryLoadGoogleSheet < 3) {
                setTimeout(() => {
                    this.gisLoaded();
                }, 1000);
            } else {
                console.error('Google API script not loaded yet');
            }
        }
    }

    handleAuthClick() {
        if (this.selectedIndex === 0) {
            this.authorizedDisplayTab = 0;
        } else {
            this.authorizedDisplayTab = 1;
        }
        tokenClient.requestAccessToken({ prompt: '' });
        this.displayWarning = false;
        tokenClient.callback = async (resp) => {
            if (resp.error !== undefined) {
                throw resp;
            }
            await this.getSheet();
        };
    }

    // Fonction qui récupère le fichier Google Sheet importé en ligne
    async getSheet() {
        let response;
        const gsheet_url: string = this.drive.link;
        const sheet_name: string = this.drive.sheet;
        try {
            response = await gapi.client.sheets.spreadsheets.values.get({
                spreadsheetId: gsheet_url.split('/')[5],
                range: sheet_name,
            });
        } catch (err) {
            if (err.status == 400) {
                this.errors = ["L'onglet '" + sheet_name + "' est introuvable"];
            } else if (err.status == 404) {
                this.errors = ['Le lien du google sheet est introuvable'];
            } else {
                this.errors = ['Une erreur est survenue lors de la lecture du google sheet'];
            }
            return;
        }
        const range = response.result;
        if (!range || !range.values || range.values.length == 0) {
            this.errors = [`La page (${sheet_name}) du fichier google sheet ${gsheet_url} est vide`];
            return;
        }
        this.csvJSON(range.values);
        this.awsService.storeCsvData(range.values, true);
        if (this.selectedIndex === 0) {
            this.displayedNameColumns = this.authorizedVariables;
        }
        if (this.selectedIndex === 1) {
            this.displayedNameColumns = this.authorizedVariables;
            this.displayedNameColumns.push('email_destinataire', 'telephone_destinataire', 'afnor1', 'afnor2', 'afnor3', 'afnor4', 'afnor5', 'afnor6', 'afnor7');
        }
        this.displayedNameColumns = this.findCommonColumnsFromGoogleDrive(range.values);
        this.scrollToBottom();
    }

    csvJSON(csv) {
        const lines = csv;
        const result = [];
        this.headers = lines[0];
        if (this.checkHeadersErrors(this.headers)) {
            let currentline = null;
            for (let i = 1; i < lines.length; i++) {
                currentline = lines[i];
                // line.length == 0 ==> empty line
                // line.length == 1 ==> incomplete line. Here we can have multiples cases
                //    One of these case, is the filter line info (the last line)
                //    In other cases, it's not good because we should have at least two data (num_abon, num_contrat)
                if (!currentline || [0, 1].indexOf(currentline.length) != -1) continue;
                const obj = {}; //{'index': i}

                for (let j = 0; j < this.headers.length; j++) {
                    obj[this.headers[j]] = currentline[j];
                }
                result.push(obj);
            }
            this.records = result;
            this.checkErrors();
            this.dataSource = new MatTableDataSource<Destinataire>(this.records.length > this.pageSize ? this.records.slice(0, this.pageSize) : this.records);
        }
    }
}
