<mat-spinner *ngIf="isLoading" class="connexion-loading"></mat-spinner>
<div class="content" [ngClass]="{'add-background' : !isHorsIris}">
    <div class="flex-box">

        <!-- Cas d'usage Fréquent -->
        <div *ngIf="!isHorsIris" class="container-automatic-parameter">
            <div class="text-with-tooltip">
                <h5 class="section-title">Cas d'usage fréquent</h5>
                <mat-icon aria-hidden="false" class="info-icon" matTooltipPosition="right"
                    matTooltip="Selectionner un paramétrage pré-défini (qui correspond à un cas d'usage fréquent) et vous gagnerez en temps. Les autres options seront automatiquement mis à jour.">info</mat-icon>
            </div>
            <mat-form-field>
                <mat-select [(ngModel)]="casUsageChoisi" (selectionChange)="updateCasdUsage(casUsageChoisi)">
                    <mat-option *ngFor="let option of casUsages" [value]="option.value">
                        {{option.label}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <!-- (HORS IRIS) Je souhaite communiquer -->
        <div *ngIf="isHorsIris">
            <span *ngIf="!displayCodeContratWarning" class="undertitle">
                Vous êtes en train de paramétrer une communication en lot Hors IRIS
                pour une ou plusieurs marques du <a
                    href="https://docs.google.com/spreadsheets/d/1GT1jrQcRxIX1oaeSf8SCjPzVUg0Xq9hlPQ0WXNXPGDM/edit?gid=150646740#gid=150646740"
                    target="_blank">référentiel</a>
            </span>
            <span *ngIf="displayCodeContratWarning" class="undertitle">
                Vous êtes en train de paramétrer une communication en lot Hors IRIS pour une
                marque hors périmètre VEF. Le logo de la marque hors référentiel doit être joint
                à l'équipe technique en amont à l’adresse suivante : <a
                    href="mailto:fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com"
                    target="_blank">fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com</a>
            </span>

        </div>
        <!-- <div *ngIf="isHorsIris" class="container-automatic-parameter">
            <div class="text-with-tooltip">
                <h5 class="section-title">Je souhaite communiquer</h5>
            </div>
            <mat-radio-group aria-label="Select an option" [(ngModel)]="marqueReferentiel"
                style="display: flex; flex-direction: column;">
                <mat-radio-button value="marque" style="margin: 0;">En tant que marque du <a
                        href="https://docs.google.com/spreadsheets/d/1o9fNkm5x6CKEhXBRi52iyU2JetA6m_1UQbznGQPhBdI/edit#gid=150646740"
                        target="_blank">référentiel</a></mat-radio-button>
                <mat-radio-button value="hors-marque" style="margin: 0;">En tant que marque hors
                    référentiel</mat-radio-button>
            </mat-radio-group>

            <span class="undertitle" *ngIf="marqueReferentiel === 'marque'"><b>Attention !</b> Le code_contrat est à
                joindre dans les
                données
            </span>
            <span class="undertitle" *ngIf="marqueReferentiel === 'hors-marque'"><b>Attention !</b> Le logo de la marque
                est à joindre à <a href="mailto:fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com"
                    target="_blank">l'équipe
                    technique</a> en amont</span>
        </div> -->

        <!-- Canaux de communication -->
        <div>
            <div class="text-with-tooltip">
                <h5 class="section-title">Canaux de communication</h5>
                <mat-icon aria-hidden="false" class="info-icon" matTooltipPosition="right"
                    matTooltip="Cela se fera par ordre de priorité, si vous choisissez e-mail en 1 et courrier en 2, alors les communications qui n’ont pas pu partir en premier lieu par e-mail partiront par courrier">info</mat-icon>
            </div>
            <p class="undertitle">Vous pouvez choisir jusqu’à 3 canaux de communications</p>
            <div class="order-block">
                <div class="input-groups">
                    <div class="flex">
                        <div class="order">1</div>
                        <mat-select [(value)]="support1" (selectionChange)="updateListSupport1(support1)"
                            placeholder="Sms, email, courrier, ...etc.">
                            <mat-option *ngFor="let item of supports" [value]="item.key">{{item.label}}
                                {{getPriceunit(item.key)}}</mat-option>
                        </mat-select>
                        <button *ngIf="support1 !== 'C' && support1" mat-icon-button aria-label="delete"
                            (click)="reset(1)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <div *ngIf="activateSecondField === false && this.supports.length > 1" class="add-field-btn"
                            (click)="activateSecondField = true">
                            <img src="assets/img/plus_sign.svg" alt="">
                        </div>
                    </div>
                    <div class="sub-input" *ngIf="support1 == 'C'">
                        <img src="assets/img/sub-arrow.svg" alt="">
                        <mat-select [(ngModel)]="affranchissement" (ngModelChange)="onChangeAffranchissement()">
                            <mat-option *ngFor="let item of postages" [value]="item.key">{{item.label}}</mat-option>
                        </mat-select>
                        <button mat-icon-button aria-label="delete" *ngIf="support1" (click)="reset(1)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="activateSecondField === true || casUsageChoisi !== 'autre'" class="input-groups">
                    <div class="flex">
                        <div class="order">2</div>
                        <mat-select [(value)]="support2" (selectionChange)="updateListSupport2(support2)"
                            placeholder="Sms, email, courrier, ...etc.">
                            <mat-option *ngFor="let item of supportList1" [value]="item.key">{{item.label}}
                                {{getPriceunit(item.key)}}</mat-option>
                        </mat-select>
                        <button mat-icon-button aria-label="delete" *ngIf="support2" (click)="reset(2)">
                            <mat-icon>close</mat-icon>
                        </button>
                        <div *ngIf="activateThirdField === false && this.supports.length > 2" class="add-field-btn"
                            (click)="activateThirdField = true">
                            <img src="assets/img/plus_sign.svg" alt="">
                        </div>
                    </div>
                    <div class="sub-input" *ngIf="support2 == 'C'">
                        <img src="assets/img/sub-arrow.svg" alt="">
                        <mat-select [(ngModel)]="affranchissement" (ngModelChange)="onChangeAffranchissement()">
                            <mat-option *ngFor="let item of postages" [value]="item.key">{{item.label}}</mat-option>
                        </mat-select>
                        <button mat-icon-button aria-label="delete" *ngIf="support2" (click)="reset(2)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
                <div *ngIf="(this.supports.length > 2 && !disabledSupport3)
                && (activateThirdField || casUsageChoisi !== 'autre')" class="input-groups">
                    <div class="flex">
                        <div class="order">3</div>
                        <mat-select [(value)]="support3" placeholder="Sms, email, courrier, ...etc.">
                            <mat-option *ngFor="let item of supportList2" [value]="item.key">{{item.label}}
                                {{getPriceunit(item.key)}}</mat-option>
                        </mat-select>
                        <button mat-icon-button aria-label="delete" *ngIf="support3" (click)="reset(3)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="sub-input" *ngIf="support3 == 'C'">
                    <img src="assets/img/sub-arrow.svg" alt="">
                    <mat-select [(ngModel)]="affranchissement" (ngModelChange)="onChangeAffranchissement()">
                        <mat-option *ngFor="let item of postages" [value]="item.key">{{item.label}}</mat-option>
                    </mat-select>
                    <button mat-icon-button aria-label="delete" *ngIf="support3" (click)="reset(3)">
                        <mat-icon>close</mat-icon>
                    </button>
                </div>
            </div>
            <div *ngIf="supports.length < 3">
                <br>
                <p *ngIf="supports.length === 1" class="undertitle">Les canaux <b>{{removedCanaux[0]}}</b> et
                    <b>{{removedCanaux[1]}}</b> ne sont pas disponibles car vous n'avez pas fourni les colonnes
                    <b>{{missingCanaux[0]}}</b> et <b>{{missingCanaux[1]}}</b>.
                </p>
                <p *ngIf="supports.length === 2" class="undertitle">Le canal <b>{{removedCanaux[0]}}</b> n'est
                    pas
                    disponible car vous n'avez pas fourni la colonne <b>{{missingCanaux[0]}}</b>.</p>
            </div>
            <div *ngIf="support1 === 'S' || support2 ==='S' || support3 === 'S'">
                <br>
                <p class="undertitle">Attention, l’envoi des SMS hors IRIS est disponible uniquement pour les
                    numéros en
                    France.</p>
            </div>
        </div>
    </div>
    <div class="flex-box middle" *ngIf="!isHorsIris">
        <!-- Type de destinataire -->
        <div>
            <div class="text-with-tooltip">
                <h5 class="section-title">Type de destinataire</h5>
                <mat-icon aria-hidden="false" class="info-icon" matTooltipPosition="right"
                    matTooltip="Vous pouvez sélectionner les deux options. Nous enverrons deux communications uniquement si les informations de contact du destinataire de facture sont différentes de celles de l’abonné dans IRIS.">info</mat-icon>
            </div>
            <div class="section">
                <mat-checkbox *ngFor="let recipientType of recipientTypes"
                    [(ngModel)]="selectedRecipientTypes[recipientType]">
                    <div class="text-with-tooltip">
                        <span>{{ recipientLabels[recipientType] }}</span>
                        <mat-icon aria-hidden="false" class="info-icon" [matTooltip]="toolTips[recipientType]"
                            matTooltipPosition="above">info</mat-icon>
                    </div>
                </mat-checkbox>
            </div>
        </div>

        <!-- Statut de l'abonnement -->
        <div>
            <div class="text-with-tooltip">
                <h5 class="section-title">Statut de l’abonnement</h5>
                <mat-icon aria-hidden="false" class="info-icon" matTooltipPosition="above"
                    matTooltip="Les abonnements pour lesquels le statut dans IRIS n’a pas été sélectionné, seront écartés de la communication">info</mat-icon>
            </div>
            <div class="section">
                <mat-checkbox [(ngModel)]="actif">Actif</mat-checkbox>
                <mat-checkbox [(ngModel)]="resiled">Résilié</mat-checkbox>
                <mat-checkbox [(ngModel)]="aboned">En cours d’abonnement</mat-checkbox>
                <mat-checkbox [(ngModel)]="closed">En cours de résiliation</mat-checkbox>
            </div>
        </div>
    </div>
    <div class="flex-box">
        <!-- Service de complétion auto -->
        <div *ngIf="!isHorsIris">
            <h5 class="section-title">Service de complétion auto</h5>
            <p class="undertitle">Utilisation du service de complétion automatique des données compteur (fill abo).</p>
            <div class="flex">
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="fillabo">
                    <mat-button-toggle [value]="true">Oui</mat-button-toggle>
                    <mat-button-toggle [value]="false">Non</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>

        <!-- Date d'émission demandée -->
        <div>
            <div class="text-with-tooltip">
                <h5 class="section-title no-margin-top">Date d'émission demandée</h5>
                <mat-icon aria-hidden="false" class="info-icon" matTooltipPosition="above"
                    matTooltip="Vous avez la possibilité de programmer votre communication pour un envoi jusqu'à J+7">info</mat-icon>
            </div>
            <div class="flex-datepicker">
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="isDateSeted">
                    <mat-button-toggle [value]="true">Oui</mat-button-toggle>
                    <mat-button-toggle [value]="false">Non</mat-button-toggle>
                </mat-button-toggle-group>
                <mat-form-field *ngIf="isDateSeted" class="date-emission">
                    <input matInput [matDatepicker]="dp" (click)="dp.open()" [formControl]="date" [(ngModel)]="dateSo"
                        [min]="minDate" [max]="maxDate" readonly>
                    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp></mat-datepicker>
                </mat-form-field>
                <div *ngIf="isDateSeted && !staggerCommunications" class="flex-datepicker">
                    <span style="font-size: 16px;"> à </span>
                    <mat-form-field class="heure-emission">
                        <input matInput aria-label="heure emission" class="pointer" [ngxTimepicker]="tp"
                            [formControl]="heureEmission" [format]="24" readonly>
                        <ngx-material-timepicker #tp></ngx-material-timepicker>
                    </mat-form-field>
                    <div class="text-with-tooltip" *ngIf="isDateSeted && !staggerCommunications">
                        <mat-icon aria-hidden="false" class="info-icon" matTooltipPosition="above"
                            matTooltip="Heure de l'émission">info</mat-icon>
                    </div>
                </div>
            </div>
        </div>

        <!-- Répartition de l'envoi -->
        <div *ngIf="isDateSeted">
            <div class="text-with-tooltip">
                <h5 class="section-title no-margin-top">Répartition de l'envoi</h5>
                <mat-icon aria-hidden="false" class="info-icon" matTooltipPosition="above"
                    matTooltip="Vous avez la possibilité d'étaler l’envoi de mes communications emails et sms.">info</mat-icon>
            </div>

            <p class="undertitle">Répartir l'envoi des communications entre 08h et 17h</p>
            <div class="flex-datepicker">
                <mat-button-toggle-group name="fontStyle" aria-label="Font Style" [(ngModel)]="staggerCommunications">
                    <mat-button-toggle [value]="true">Oui</mat-button-toggle>
                    <mat-button-toggle [value]="false">Non</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
        </div>

        <!-- (HORS IRIS) Code région et Code territoire -->
        <div *ngIf="isHorsIris" style="display: flex; flex-direction: row; align-items: center; gap: 35px;">
            <div style="width: 100%;">
                <div class="text-with-tooltip">
                    <h5 class="section-title no-margin-top">Code région</h5>
                </div>

                <mat-form-field style="width: 100% !important;">
                    <mat-select class="full" [(value)]="selectedRegion"
                        (selectionChange)="onRegionSelect($event.value)">
                        <mat-option *ngFor="let region of regionsTerritoires | keyvalue" [value]="region.key">
                            {{ '(' + region.key + ') ' + region.value[0].libelle_region }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div style="width: 100%;">
                <div class="text-with-tooltip">
                    <h5 class="section-title no-margin-top">Code territoire</h5>
                </div>

                <mat-form-field style="width: 100% !important;">
                    <mat-select class="full" [(value)]="selectedTerritoire" [disabled]="!selectedRegion">
                        <mat-option *ngFor="let territoire of regionsTerritoires[selectedRegion]"
                            [value]="territoire.code">
                            {{ '(' + territoire.code + ') ' + territoire.libelle }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>
<div class="button-group">
    <button mat-raised-button class="btn-previuos" (click)="previous()">
        <mat-icon>chevron_left</mat-icon>
        Précédent
    </button>
    <button mat-stroked-button class="btn-next" (click)="checkRegisterLetter()" [disabled]="isReady()">
        Valider
    </button>
</div>
<p class="error" *ngIf="loading == 'error'">Échec du chargement des données</p>
<!-- <div class="content-spinner" *ngIf="loading == 'processing'">
    <h4 class="loading">
        Merci de patienter, Mercure prépare la synthèse d’envoi de vos communications
    </h4>
    <div class="spinner-timer">
        <mat-spinner></mat-spinner>
        <span class="number">{{displayTimeString}}</span>
    </div>
    <div style="min-height: 23px;"></div>
</div> -->