<div class="resume-block" *ngIf="!isDataProcessing">
    <div *ngIf="displayWarning" class="warning-message">
        Attention, au moins un des critères suivants a été trouvé et nécessite de faire valider la communication par
        un responsable :
        <ul>
            <li>Communication dépassant le coût total des 1000 euros</li>
            <li>Communication qui touche plus de 2000 consommateurs</li>
        </ul>
    </div>
    <div class="title">
        <ng-container *ngIf="!loading">
            <div class="support comm">
                <div class="attribut">Nombre de communications à envoyer/demandées</div>
                <div *ngIf="isBothRecipients()" class="value">{{ total + ' / ' + data.nb_communications }}</div>
                <div *ngIf="!isBothRecipients()" class="value">{{ total + ' / ' + target }}</div>
            </div>
            <div class="support" *ngIf="!isHorsIris">
                <div class="attribut">Type de destinataire</div>
                <div class="value">{{ getTypeDest() }}</div>
            </div>
            <div class="support">
                <div class="attribut">Date d'envoi</div>
                <div class="value">{{ com.date | date: 'dd/MM/yyyy' }}</div>
            </div>
            <div class="support" *ngIf="heure">
                <div class="attribut">Heure d'envoi</div>
                <div class="value">{{ heure }}</div>
            </div>
            <div class="support" *ngIf="idlot">
                <div class="attribut">ID du lot</div>
                <div class="value">{{ idlot }}</div>
            </div>
            <div class="support total" matTooltipPosition="right"
                matTooltip="Pour les courriers à l’étranger, le prix dépend du pays de la destination du courrier.">
                <div class="attribut">Coût total estimé</div>
                <div class="value">{{ data.prix }}€ </div>
            </div>
        </ng-container>
    </div>
    <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
    <div class="dpFlex">
        <div class="item">
            <h4>Répartition des communications par canal </h4>
            <div class="bloc-row" *ngIf="!loading">
                <div class="bloc" *ngFor="let support of supports">
                    <div class="flex">
                        <div class="details">
                            <div class="key">{{support.label}}</div>
                            <div class="label">{{support.data}}</div>
                        </div>
                        <circle-progress [percent]="percentage(support.data)" [radius]="30">
                        </circle-progress>
                    </div>
                    <div class="details" *ngIf="support.label == 'Courier'" matTooltipPosition="below"
                        matTooltip="Etr est le nombre de courriers à l’étranger et FR est le nombre des courriers en France">
                        <div class="label"> Fr: {{support.fr}} | Etr: {{support.etr}}</div>
                    </div>
                    <div class="details aff" *ngIf="support.label == 'Courier'">
                        <div class="key">Affranchissement</div>
                        <div class="label">{{labelaffr}}</div>
                    </div>
                </div>

            </div>
            <div class="bloc-loading" *ngIf="loading">
                <mat-spinner></mat-spinner>
            </div>
        </div>
        <div class="item">
            <div class="bloc-title">
                <h4 class="key">Communications en échec </h4>
                <div class="label" *ngIf="!loading">{{data.nb_E_INVALID_IRIS_DATA + data.nb_E_ABONNEMENT_INEXISTENT +
                    data.nb_E_INVALID_STATUT + data.nb_E_INVALID_DATA}}</div>
            </div>
            <div class="bloc-error">
                <ng-container *ngIf="!loading">
                    <div class="flex">
                        <div class="key">Absence des données de contacts {{ isHorsIris? "": "dans IRIS" }} </div>
                        <div class="label">{{ !isHorsIris ? data.nb_E_INVALID_IRIS_DATA : data.nb_E_INVALID_DATA}}</div>
                    </div>
                    <div class="flex" *ngIf="!isHorsIris">
                        <div class="key">Abonnement inexistant dans IRIS</div>
                        <div class="label">{{data.nb_E_ABONNEMENT_INEXISTENT}} </div>
                    </div>
                    <div class="flex" *ngIf="!isHorsIris">
                        <div class="key">Abonnement dont le statut IRIS n’a pas été sélectionné</div>
                        <div class="label">{{data.nb_E_INVALID_STATUT}} </div>
                    </div>
                    <div class="flex">
                        <div class="key">Autres</div>
                        <div class="label">{{data.nb_E_INVALID_CANAL + data.nb_E_MISSING_TEMPLATE_DOCUMENT +
                            data.nb_E_UNKNOWN + data.nb_E_INVALID_ABO_DATA + data.nb_E_NO_CANAL}} </div>
                    </div>

                    <div class="flex" (click)="downloadFile()">
                        <div class="key underline">Télécharger le détail des communication en échec</div>
                        <div class="label">
                            <span class="material-icons">file_download</span>
                        </div>
                    </div>
                </ng-container>
            </div>
            <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>
        </div>
    </div>
    <div *ngIf="!loading">
        <h4>Destinataires</h4>
        <div class="title synthese" *ngIf="report.marques">
            <div class="support">
                <div class="header">
                    Total marques : <span>{{report.marques.total}}</span>
                </div>
                <div class="dist-column">
                    <div *ngFor="let item of report.marques.items">{{item.key}} ({{item.value}})</div>
                </div>
            </div>
            <div class="support">
                <div class="header">
                    Total codes contrats : <span>{{report.contrats.total}}</span>
                </div>
                <div class="dist-column">
                    <div *ngFor="let item of report.contrats.items">{{item.key}} ({{item.value}})</div>
                </div>
            </div>
            <div class="support">
                <div class="header">
                    Total communes : <span>{{report.communes.total}}</span>
                </div>
                <div class="dist-column">
                    <div *ngFor="let item of report.communes.items">{{item.key}} ({{item.value}})</div>
                </div>
            </div>
        </div>
    </div>

    <div class="button-group">
        <button mat-raised-button class="btn-previuos" (click)="previous()">
            Précèdent
        </button>
        <button mat-raised-button class="btn-previuos" (click)="print()">
            <mat-icon>print</mat-icon>
            Imprimer
        </button>
        <button mat-stroked-button class="btn-next" (click)="submit()" *ngIf="!loading">
            Valider
        </button>
    </div>
</div>
<div class="content-spinner" *ngIf="isDataProcessing">
    <h4 class="loading">
        Merci de patienter, Mercure prépare la synthèse d’envoi de vos communications
    </h4>
    <div class="spinner-timer">
        <mat-spinner></mat-spinner>
        <span class="number">{{displayTimeString}}</span>
    </div>
    <div style="min-height: 23px;">
        <h5 *ngIf="isTakingTooLong" class="is-taking-long">
            Le traitement est en cours mais il prend plus de temps que prévu.
            Ne fermez pas la page.
        </h5>
    </div>
</div>