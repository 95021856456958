export const useCase = {
    relaunch: {
        label: 'Relance',
        cases: [
            {
                key: 'relaunch',
                label: 'Impayé consommateur',
                postages: ['EGC'],
                supports: [
                    { key: 'M', label: 'Mail' },
                    { key: 'S', label: 'SMS' },
                ],
            },
        ],
    },
    intervention: {
        label: 'Intervention compteur',
        cases: [
            {
                key: 'replace',
                label: 'Prise de RDV-Remplacement compteur',
                postages: ['EGC'],
                supports: [
                    { key: 'M', label: 'Mail' },
                    { key: 'S', label: 'SMS' },
                    { key: 'C', label: 'Courier' },
                ],
            },
            {
                key: 'replaceRelance',
                label: 'Relance de prise de RDV-Remplacement compteur',
                postages: ['EGC', 'LS', 'LR', 'LRAR'],
                supports: [
                    { key: 'M', label: 'Mail' },
                    { key: 'S', label: 'SMS' },
                    { key: 'C', label: 'Courier' },
                ],
            },
            {
                key: 'statement',
                label: 'Relevé Index',
                postages: ['EGC'],
                supports: [
                    { key: 'M', label: 'Mail' },
                    { key: 'S', label: 'SMS' },
                    { key: 'C', label: 'Courier' },
                ],
            },
        ],
    },
    communication: {
        label: 'Communication ponctuelle',
        cases: [
            {
                key: 'crise',
                label: 'Situation de crise',
                postages: ['EGC'],
                supports: [
                    { key: 'M', label: 'Mail' },
                    { key: 'S', label: 'SMS' },
                    { key: 'C', label: 'Courier' },
                ],
            },
        ],
    },
};
