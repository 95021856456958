import { Component, OnInit,  EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-config-sending',
  templateUrl: './config-sending.component.html',
  styleUrls: ['./config-sending.component.scss']
})
export class ConfigSendingComponent implements OnInit {

  typeSelected = '';
  date = new UntypedFormControl(new Date());
  time = new UntypedFormControl();
  minDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
  @Output() nextStep = new EventEmitter<any>();
  @Output() previousStep = new EventEmitter<string>();
  @Input()
  set com(val: any) {
      this.typeSelected = val.typeSelected;
      this.date = new UntypedFormControl(val.date);
      this.time = new UntypedFormControl(val.time);
  }

  next() {
      if (this.typeSelected == 'difer')
        this.nextStep.emit({typeSelected: this.typeSelected, date: this.date.value, time: this.time.value});
      else
        this.nextStep.emit({typeSelected: this.typeSelected});
  }

  previous() {
      this.previousStep.emit();
  }

  isReady() {
    if (this.typeSelected == 'difer')
      return !(this.date.value && this.time.value);
    else
      return  !this.typeSelected
  }
  
  ngOnInit(): void {
  }

}
