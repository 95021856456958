<h4 mat-dialog-title>Attention</h4>
<div mat-dialog-content class="message-block">
  <div class="fields-container">
    <span>Si vous avez apporté des modifications à votre fichier, veuillez le charger de nouveau en
      appuyant sur le bouton “charger le fichier”.</span>
    <br>
    <span> Souhaitez-vous quand-même passer à l'étape suivante ?</span>
  </div>
  <div mat-dialog-actions class="buttons-container">
    <button mat-button mat-dialog-close class="cancel-btn" (click)="close()">Annuler</button>
    <button mat-button (click)="confirmSameTable()" class="primary-btn">Confirmer</button>
  </div>
</div>