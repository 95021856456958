import { Storage } from 'aws-amplify';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
})
export class MessagesComponent implements OnInit {
    public loading = false;
    public newMessage: string = '';
    public selectedColorObject: { color: string; textColor: string } = { color: '', textColor: '' };
    public fileName: string = 'message.json';
    @ViewChild('messageTextarea') messageTextarea: ElementRef;
    public retryCount = 0;

    constructor(private utilsService: UtilsService) {}

    ngOnInit() {
        this.loading = true;
        setTimeout(() => {
            this.loadContent();
        }, 100);
    }

    public async loadContent() {
        this.loading = true;
        Storage.get(this.fileName, { download: true, expires: 10, cacheControl: 'no-cache' })
            .then((result) => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    const JSONmessage = JSON.parse(event.target.result);
                    this.newMessage = JSONmessage.message;
                    this.selectedColorObject = {
                        color: JSONmessage.color,
                        textColor: JSONmessage.textColor,
                    };
                    setTimeout(() => {
                        this.adjustTextareaHeightOnInit();
                    }, 0);
                    this.utilsService.successNotification('Contenu du message récupéré avec succès.');
                };
                reader.readAsText((result as any).Body);
                this.loading = false;
            })
            .catch((err) => {
                if (this.retryCount < 3) {
                    this.retryCount++;
                    setTimeout(() => {
                        this.loadContent();
                    }, 500);
                } else {
                    this.utilsService.errorNotification('La récupération du message a échouée.', err);
                    console.error('Erreur: ', err);
                    this.loading = false;
                }
            });
    }

    public removeMessage() {
        this.newMessage = '';
        this.updateMessage();
    }

    public updateMessage() {
        Storage.put(this.fileName, JSON.stringify({ message: this.newMessage, color: this.selectedColorObject.color, textColor: this.selectedColorObject.textColor }), {
            contentType: 'application/json',
        })
            .then((result) => {
                this.utilsService.successNotification('Le contenu a été sauvegardé avec succès.');
            })
            .catch((err) => {
                this.utilsService.errorNotification('La sauvegarde du contenu a échouée.', err);
                console.error(err);
            });
    }

    public adjustTextareaHeight(event: any): void {
        this.adjustHeight(event.target);
    }

    public adjustTextareaHeightOnInit(): void {
        const textarea: HTMLTextAreaElement = this.messageTextarea.nativeElement;
        this.adjustHeight(textarea);
    }

    private adjustHeight(textarea: HTMLTextAreaElement): void {
        setTimeout(() => {
            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;
        }, 0);
    }
}
