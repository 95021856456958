<mat-spinner *ngIf="loading" class="connexion-loading"></mat-spinner>
<div class="page-layout">
  <div class="admin-layout">
    <div style="display: flex; flex-direction: row; align-items: center; gap: 10px; justify-content: space-between;">
      <h5 class="color-blue main-title">Modification du message d'accueil</h5>
      <button class="tertiary-btn" (click)="loadContent()">
        <span>Rafraichir le contenu</span>
        <i class="material-icons" style="color: #0d47a1;">refresh</i>
      </button>
    </div>
    <br>
    <div class="pricing-form-container">
      <div class="message">
        <mat-hint align="end" [ngClass]="{'limit-reached': newMessage?.length === 255}">
          {{newMessage?.length || 0}} / 255</mat-hint>
        <mat-form-field appearance="fill" class="custom-textarea">
          <mat-label>Message d'accueil</mat-label>
          <textarea #messageTextarea matInput [(ngModel)]="newMessage" maxlength="255"
            (input)="adjustTextareaHeight($event)"></textarea>
        </mat-form-field>
      </div>
      <div class="color-layout">
        <mat-form-field appearance="fill" class="colors">
          <mat-label>Couleur de fond</mat-label>
          <mat-select [(ngModel)]="selectedColorObject">
            <mat-option [value]="{color: '#E8C5C5', textColor: '#cf0000'}">Rouge</mat-option>
            <mat-option [value]="{color: '#C5D2E8', textColor: '#0047b5'}">Bleu</mat-option>
            <mat-option [value]="{color: '#D3E0CF', textColor: '#246d00'}">Vert</mat-option>
          </mat-select>

        </mat-form-field>
        <div class="color-block" [style.backgroundColor]="selectedColorObject.color"></div>
      </div>
    </div>
    <div class="buttons-layout">
      <button mat-raised-button color="error" (click)="removeMessage()">Supprimer le message d'accueil</button>
      <button mat-raised-button color="primary" (click)="updateMessage()">Mettre à jour le message d'accueil</button>
    </div>
  </div>
</div>