import { Injectable } from '@angular/core';
import { Amplify, Storage } from 'aws-amplify';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AwsService {
    private csvData: any[];
    private isGSheetDocument: boolean = false;
    private contentLoadedSource = new BehaviorSubject<boolean>(false);
    public contentLoaded$ = this.contentLoadedSource.asObservable();

    constructor(private http: HttpClient) {}

    public initAmplify(): void {
        Amplify.configure({
            Auth: {
                identityPoolId: environment.identityPoolId,
                region: environment.awsRegion,
                identityPoolRegion: environment.awsRegion,
                mandatorySignIn: true,
            },
            Storage: {
                AWSS3: {
                    bucket: environment.s3BucketFlux, //REQUIRED -  Amazon S3 bucket name
                    region: environment.awsRegion, //OPTIONAL -  Amazon service region
                    isObjectLockEnabled: false, //OPTIONAl - Object Lock parameter
                },
            },
        });
        Storage.configure({
            customPrefix: {
                public: 'MERCURE/',
                protected: 'protected/',
                private: 'private/',
            },
        });
    }

    public setContentLoaded(loaded: boolean): void {
        this.contentLoadedSource.next(loaded);
    }

    // Méthode pour stocker les données du tableau importé
    public storeCsvData(data: any[], isGoogleSheet: boolean = false) {
        this.csvData = data;
        this.isGSheetDocument = isGoogleSheet;
    }

    // Méthode pour récupérer les données du tableau importé
    public getCsvData(): any[] {
        return [this.csvData, this.isGSheetDocument];
    }

    getpresignedurls() {
        return this.http.get<any>(environment.apiURL + '/' + 'file/presigned?type=json&source=MCUR');
    }

    public uploadfileAWSS3(data, file) {
        const fd = new FormData();
        Object.keys(data.fields).forEach((key) => {
            fd.append(key, data.fields[key]);
        });
        fd.append('file', file);
        return this.http.post(data.url, fd);
    }

    synthese(idlot) {
        return this.http.get<any>(environment.apiURL + '/informations', { params: { 'id-lot-prefix': idlot } });
    }

    priceSupport() {
        return this.http.get<any>(environment.apiURL + '/prix');
    }

    updatePrices(data): Observable<any> {
        return this.http.put<any>(environment.apiURL + '/prix', { prix_par_canal: data });
    }

    getCanauxEcartes() {
        return this.http.get<any>(environment.apiURL + '/commerces/v1/marques/canaux-ecartes');
    }

    compose(data: any, isForcedToSend?: boolean, fillContact?: boolean) {
        if (!isForcedToSend) {
            isForcedToSend = false;
        }
        const headers = new HttpHeaders().set('Content-Type', 'application/json; charset=utf-8').set('Type-Communication', 'lot').set('Cache-Control', 'no-cache').set('aggregate', 'true');
        const body = {
            ...data,
            'fill-marque': true,
            'fill-contact': fillContact,
            'mode-interface': true,
            'systeme-emetteur': 'MCUR',
            'communication_crise': isForcedToSend,
            'datamaster': 'DataMaster_Lot_Generique.wfd',
        };
        return this.http.post<any>(environment.apiURL + '/aggregate', body, { headers });
    }

    vaidateLot(id_lot_prefix) {
        return this.http.post<any>(environment.apiURL + '/lot/validation', { id_lot_prefix });
    }

    getReport(id_lot_prefix) {
        return this.http.get<any>(environment.apiURL + '/erreurs', { params: { 'id-lot-prefix': id_lot_prefix } });
    }
}
