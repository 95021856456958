import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgFor } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import localeFr from '@angular/common/locales/fr';
import { registerLocaleData } from '@angular/common';
import { TINYMCE_SCRIPT_SRC, EditorModule } from '@tinymce/tinymce-angular';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { UserLoginService } from './services/user-login.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// Interceptor
import { HttpErrorInterceptor } from './services/http-error.interceptor';
// Modules
import { MaterialModule } from './material/material.module';
import { ConfigurationModule } from './modules/configuration/configuration.module';
import { ComponentsModule } from './components/components.module';
import { LandingComponent } from './pages/landing/landing.component';
import { AdminModule } from './modules/admin/admin.module';
import { SupportModule } from './modules/support/support.module';

export function initializeAuthProvider(userService: UserLoginService): Function {
    return () => {
        userService.init();
    };
}

registerLocaleData(localeFr);
@NgModule({
    declarations: [AppComponent, LandingComponent],
    imports: [
        SharedModule,
        BrowserModule,
        AppRoutingModule,
        NgbModule,
        MaterialModule,
        BrowserAnimationsModule,
        ComponentsModule,
        ConfigurationModule,
        AdminModule,
        SupportModule,
        NgFor,
        EditorModule,
        ReactiveFormsModule,
        FormsModule,
        ToastrModule.forRoot(),
    ],
    providers: [
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },
        { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
        { provide: APP_INITIALIZER, useFactory: initializeAuthProvider, multi: true, deps: [UserLoginService] },
    ],
    entryComponents: [],
    bootstrap: [AppComponent],
})
export class AppModule {}
