<h4 mat-dialog-title>Cannaux écartés détectés</h4>
<div mat-dialog-content class="message-block">
  <div class="fields-container">
    <h6>Attention !</h6>
    <div>
      <span [innerHTML]="message"></span>
      <span> Souhaitez-vous quand-même confirmer cette action ?</span>
    </div>
  </div>
  <div mat-dialog-actions class="buttons-container">
    <button mat-button mat-dialog-close class="cancel-btn">Annuler</button>
    <button mat-button (click)="standardSendCommunication()" class="primary-btn">Envoi standard*</button>
    <button mat-button (click)="forceSendCommunication()" class="primary-btn force">Forcer l'envoi**</button>
  </div>
  <div class="info-block">
    <span><b>*</b>Le canal écarté ne sera pas utilisé</span>
    <span><b>**</b>Envoi sur le canal écarté</span>
  </div>
</div>