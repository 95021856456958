<mat-spinner *ngIf="loading" class="connexion-loading"></mat-spinner>
<div class="page-layout">
  <div class="admin-layout">
    <div style="display: flex; flex-direction: row; align-items: center; gap: 10px; justify-content: space-between;">
      <h5 class="color-blue main-title">MVariables Mercure acceptées/affichées ({{nbVariables}})</h5>
      <button class="tertiary-btn" (click)="loadContent()">
        <span>Rafraichir le contenu</span>
        <i class="material-icons" style="color: #0d47a1;">refresh</i>
      </button>
    </div>
    <h6>Seules les variables définies ci-dessous seront visibles dans l'aperçu lors du chargement du tableau.</h6>
    <br>
    <div class="variables-form-container">
      <div *ngFor="let variable of displayedNameColumns; let i = index; trackBy: trackByFn" class="message">
        <mat-form-field appearance="fill">
          <mat-label>Nom de variable</mat-label>
          <input matInput [(ngModel)]="displayedNameColumns[i]" (input)="onInputChange()" type="text"
            [disabled]="variable === 'numero_abonnement' || variable === 'code_contrat'">
        </mat-form-field>
        <button mat-icon-button class="delete-btn" (click)="deleteVariable(i)"
          [disabled]="variable === 'numero_abonnement' || variable === 'code_contrat'">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
      <button mat-button class="tertiary-btn" (click)="addVariableField()">+ Ajouter une variable</button>
    </div>
    <div class="buttons-layout">
      <button mat-raised-button color="primary" (click)="saveVariableList()" class="full" [disabled]="!isModified">
        Sauvegarder les modifications</button>
    </div>
  </div>
</div>