import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { UtilsService } from 'src/app/services/utils.service';
import { AwsService } from 'src/app/services/aws.service';

@Component({
    selector: 'app-prices',
    templateUrl: './prices.component.html',
    styleUrls: ['./prices.component.scss'],
})
export class PricesComponent implements OnInit {
    public loading = false;
    public formNameMapping = {
        'S': 'SMS',
        'M': 'Email',
        'C_EGC': 'Courrier standard',
        'C_ELS': 'Courrier en lettre suivie',
        'C_RRS': 'Courrier recommandé sans AR',
        'C_RRA': 'Courrier recommandé avec AR',
        'C_ETR': "Courrier à l'étranger",
    };

    public pricingForm: FormGroup;
    public initialFormValues: any = {};
    public changedFields: any = {};
    public objectKeys = Object.keys;

    constructor(private formBuilder: FormBuilder, public dialog: MatDialog, private awsService: AwsService, private utilsService: UtilsService) {}

    ngOnInit() {
        this.loading = true;
        // Création du formulaire avec les valeurs initiales
        this.pricingForm = this.formBuilder.group({
            S: [0],
            M: [0],
            C_EGC: [0],
            C_ELS: [0],
            C_RRS: [0],
            C_RRA: [0],
            C_ETR: [0],
        });

        this.awsService.priceSupport().subscribe(
            (response: any) => {
                this.loading = false;
                const prices = response.prix_par_canal;
                this.initialFormValues = prices; // mise à jour des valeurs initiales avec les prix récupérés
                this.pricingForm.patchValue(prices); // mise à jour du formulaire avec les prix récupérés
                this.utilsService.successNotification('Prix récupérés avec succès');
            },
            (error) => {
                this.loading = false;
                console.error('Erreur lors de la récupération des prix', error);
            }
        );

        this.pricingForm.valueChanges.subscribe((newValues) => {
            this.changedFields = {};
            for (const field in newValues) {
                if (newValues[field] !== this.initialFormValues[field]) {
                    this.changedFields[field] = {
                        oldValue: this.initialFormValues[field],
                        newValue: newValues[field],
                    };
                }
            }
        });
    }

    onSubmit(): void {
        const dialogRef = this.dialog.open(ConfirmationModalComponent, {
            data: {
                changedFields: this.changedFields,
                formNameMapping: this.formNameMapping,
                initialFormValues: this.initialFormValues,
            },
        });
        dialogRef.afterClosed().subscribe((result) => {
            if (result?.event === 'saveChanges') {
                this.saveChanges();
            }
        });
    }

    public saveChanges(): void {
        if (this.pricingForm.valid) {
            this.loading = true;
            const updatedPrices = this.pricingForm.value;
            console.info('Mise à jour des prix avec les valeurs suivantes:', updatedPrices);
            this.awsService.updatePrices(updatedPrices).subscribe(
                (response) => {
                    this.loading = false;
                    this.utilsService.successNotification('Les prix ont été mis à jour avec succès');
                },
                (error) => {
                    this.loading = false;
                    this.utilsService.errorNotification(error.message, 'Echec de mise à jour des prix');
                    console.error('Erreur lors de la mise à jour', error);
                }
            );
        }
    }
}
