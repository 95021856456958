<div class="bloc-container">
    <div class="bloc-import card">
        <mat-tab-group [(selectedIndex)]="selectedIndex">
            <mat-tab label="Envoi lots IRIS">
                <ng-container *ngTemplateOutlet="Block1"></ng-container>
            </mat-tab>
            <mat-tab label="Envoi des lots Hors IRIS">
                <ng-container *ngTemplateOutlet="Block1"></ng-container>
            </mat-tab>
            <mat-tab label="Envoi via le catalogue de service">
                <ng-container *ngTemplateOutlet="Block3"></ng-container>
            </mat-tab>
            <mat-tab label="Envoi des courriers en masse">
                <ng-container *ngTemplateOutlet="Block4"></ng-container>
            </mat-tab>
        </mat-tab-group>


        <!-- Onglet 1 -->
        <ng-template #block1>
            <div class="bloc-column-auto">
                <div *ngIf="displaySheetBloc">
                    <span *ngIf="selectedIndex === 1" class="main-font">
                        Pour les envois Hors IRIS,
                        les données de contact (email, adresse postale
                        et numéro de téléphone) sont renseignées avec les données en entrée
                        <a
                            href="https://docs.google.com/spreadsheets/d/1XSZutTzRj_wnyXOas8fmmg1Acyl1F6ACpvRZUVll1tw/edit#gid=666559665">(exemple
                            de fichier gg sheet Hors IRIS)</a>
                    </span>
                    <div>
                        <h5 class="color-blue main-title">
                            Importer un fichier Google Sheet
                        </h5>
                    </div>
                    <div class="height-50">
                        <mat-form-field class="drive-full-width" appearance="outline">
                            <mat-label class="color-gray">Ajouter l'URL du Google Sheet</mat-label>
                            <input matInput [(ngModel)]="drive.link">
                        </mat-form-field>
                    </div>
                    <div class="bloc-sheet-submit">
                        <div class="sheet-form">
                            <mat-form-field class="drive-full-width" appearance="outline">
                                <mat-label class="color-gray">Nom de l'onglet cible</mat-label>
                                <input matInput [(ngModel)]="drive.sheet">
                            </mat-form-field>
                        </div>
                        <div class="margin-left-10">
                            <button mat-stroked-button class="primary-btn-outline" (click)="handleAuthClick()"
                                [ngClass]="{'active': (drive.sheet && drive.link)}">
                                Charger fichier
                            </button>
                        </div>
                    </div>
                </div>
                <br>
                <div class="line-seperator-group" *ngIf="displaySheetBloc">
                    <div class="line-item">
                        <div class="line"></div>
                    </div>
                    <div class="item">
                        OU
                    </div>
                    <div class="line-item">
                        <div class="line"></div>
                    </div>
                </div>
                <br>
                <div>
                    <h5 class="color-blue main-title">
                        Importer un fichier local
                    </h5>
                    <div class="add" onclick="document.querySelector('#fileImportInput').click()">
                        <div><i class="material-icons">save_alt</i></div>
                        <div>CSV ou XLS</div>
                        <div>
                            <a class="color-blue main-font">
                                Choisir un fichier
                            </a>
                            à importer
                        </div>
                    </div>
                    <div class="file" *ngIf="filename">
                        <mat-icon>attach_file</mat-icon>
                        <span class="material-icons">file_download</span>
                        <div class="filename">
                            <label>{{filename}}</label>
                            <mat-icon (click)="resetFile()">highlight_off</mat-icon>
                        </div>
                    </div>
                    <input type="file" id="fileImportInput" (change)="onFileChange($event)" style="display: none"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                    <mat-progress-bar class="margin-top-20" mode="query" *ngIf="loading"></mat-progress-bar>
                </div>
                <div class="file" *ngIf="filename">
                    <mat-icon>attach_file</mat-icon>
                    <span class="material-icons">file_download</span>
                    <div class="filename">
                        <label>{{filename}}</label>
                        <mat-icon (click)="resetFile()">highlight_off</mat-icon>
                    </div>
                </div>
                <input type="file" id="fileImportInput" (change)="onFileChange($event)" style="display: none"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            </div>
            <div style="width: 100%; display: flex; justify-content: center;">
                <button mat-stroked-button class="align-self-center primary-btn" (click)="next(selectedIndex)"
                    [disabled]="isReady()">
                    Suivant
                </button>
            </div>

            <!-- Tableau CSV affiché -->
            <div class="bloc-container margin-top-20"
                *ngIf="(errors?.length > 0 || (records && errors?.length === 0 && records?.length > 0)) && ((selectedIndex === 0 && authorizedDisplayTab === 0) || (selectedIndex === 1 && authorizedDisplayTab === 1))">
                <div class="">
                    <div *ngIf="displayCodeContratWarning" class="fields-container">
                        <span>Le logo de la marque hors référentiel (voir le <a target="_blank"
                                href="https://docs.google.com/presentation/d/1DHCXvbPjQQcwhVU92oM0tX6MjdwmMBRu-Qx8dmDLst0/edit#slide=id.g2ccb4c3ffc5_0_9">
                                mode opératoire pour la résolution des logos
                            </a>
                            ) doit être joint à
                            <a href="mailto:fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com"
                                target="_blank">
                                l'équipe technique</a> en amont à l'adresse suivante :
                            <br>
                            <b>fr.water.ist.conso-srvc-editique.run-editique.all.groups@veolia.com</b>
                        </span>
                    </div>
                    <div *ngIf="errors?.length > 0" class="list-error">
                        <h1>
                            Erreurs lors du chargement du fichier
                        </h1>
                        <p class="error" *ngFor="let error of errors" [innerHTML]="error | sanitizeHtml">
                        </p>
                    </div>

                    <div *ngIf="records && errors?.length === 0 && records?.length > 0" style="padding: 20px;">
                        <label class="count inter-font" *ngIf="records && records?.length > 0">
                            Nombre de résultats: <b>{{records?.length}}</b>
                        </label>
                        <br>
                        <span>Sont affichées uniquement les variables de votre tableau prise en compte par Mercure
                            ({{displayedNameColumns?.length + " colonnes sur " + headers?.length }})</span>
                        <section class="table-container mat-elevation-z8" tabindex="1">
                            <table mat-table [dataSource]="dataSource" *ngIf="records && records?.length > 0">
                                <ng-container matColumnDef="{{column}}" *ngFor="let column of displayedNameColumns">
                                    <th mat-header-cell *matHeaderCellDef matTooltip="{{column == 'afnor1' ? 'afnor1' :
                                    column == 'afnor2' ? 'afnor2' :
                                    column == 'afnor3' ? 'afnor3' :
                                    column == 'afnor4' ? 'afnor4' :
                                    column == 'afnor5' ? 'afnor5' :
                                    column == 'afnor6' ? 'afnor6' :
                                    column == 'afnor7' ? 'afnor7' : ''}}">
                                        {{column == 'afnor1' ? 'Nom du destinataire' :
                                        column == 'afnor2' ? 'Complément d\'adresse 1' :
                                        column == 'afnor3' ? 'Complément d\'adresse 2' :
                                        column == 'afnor4' ? 'Numéro et nom de la rue' :
                                        column == 'afnor5' ? 'Lieu dit' :
                                        column == 'afnor6' ? 'Code postal et nom de la ville' :
                                        column == 'afnor7' ? 'Pays' : column}}
                                    </th>
                                    <td mat-cell *matCellDef="let element">{{element[column]}}</td>
                                </ng-container>
                                <tr mat-header-row *matHeaderRowDef="displayedNameColumns; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedNameColumns;"></tr>
                            </table>
                        </section>
                    </div>
                </div>
            </div>
        </ng-template>

        <!-- Onglet 2 -->
        <ng-template #block2>
            <!-- <app-settings-send [isConfigPage]="false" [com]="com" #settingsRef
                (nextStep)="next($event)"></app-settings-send> -->
            <ng-container *ngTemplateOutlet="block1"></ng-container>
        </ng-template>

        <!-- Onglet 3 -->
        <ng-template #block3>
            <div
                style="padding: 20px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <strong class="align-self-center">
                    Envoi via le catalogue de service
                </strong>
                <div class="arrow-item-with-text margin-top-20">
                    <div>
                        <mat-icon class="color-green">trending_flat</mat-icon>
                    </div>
                    <div class="margin-left-10">Besoin de faire un envoi avec plus de {{rowsLimit.toLocaleString()}}
                        communications
                    </div>
                </div>
                <div class="arrow-item-with-text">
                    <div>
                        <mat-icon class="color-green">trending_flat</mat-icon>
                    </div>
                    <div class="margin-left-10">Besoin d'accompagnement pour une communication complexe</div>
                </div>

                <a class="margin-top-20 align-self-center primary-btn padding-10 text-align-center no-underline"
                    *ngIf="lienCatalogueService" href="{{lienCatalogueService}}">
                    Catalogue de service
                </a>
            </div>
        </ng-template>

        <!-- Onglet 4 -->
        <ng-template #block4>
            <div
                style="padding: 20px; display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <strong class="align-self-center">
                    Envoi des courriers en masse
                </strong>
                <div class="arrow-item-with-text margin-top-20">
                    <div>
                        <mat-icon class="color-green">trending_flat</mat-icon>
                    </div>
                    <div class="margin-left-10">Besoin d'envoyer via courrier un document au format pdf déjà publiposté
                    </div>
                </div>
                <div class="arrow-item-with-text">
                    <div>
                        <mat-icon class="color-green">trending_flat</mat-icon>
                    </div>
                    <div class="margin-left-10">Pas de besoin d'archiver ni de tracer dans IRIS les envois</div>
                </div>
                <a class="margin-top-20 align-self-center primary-btn padding-10 text-align-center no-underline"
                    *ngIf="lienCourrierEnMasse" href="{{lienCourrierEnMasse}}">
                    Module des courriers en masse
                </a>
            </div>
        </ng-template>
        <br>
    </div>

</div>