import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';

/* Modules */
import { SharedModule } from 'src/app/shared/shared.module';
import { AdminRoutingModule } from './admin-routing.module';
import { MaterialModule } from 'src/app/material/material.module';

/* Components */
import { AdminComponent } from './admin.component';
import { PricesComponent } from './prices/prices.component';
import { MessagesComponent } from './messages/messages.component';
import { EditVariablesComponent } from './edit-variables/edit-variables.component';
import { HelpAndSupportComponent } from './help-and-support/help-and-support.component';
import { ConfirmationModalComponent } from './prices/confirmation-modal/confirmation-modal.component';

@NgModule({
    declarations: [AdminComponent, PricesComponent, EditVariablesComponent, MessagesComponent, ConfirmationModalComponent, HelpAndSupportComponent],
    imports: [CommonModule, AdminRoutingModule, ReactiveFormsModule, MaterialModule, FormsModule, SharedModule],
})
export class AdminModule {}
