import { Auth } from 'aws-amplify';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable, Injector, NgZone, EventEmitter } from '@angular/core';

import { User } from '../models/user';
import { MercureRoles } from '../enums/role.enum';
import { URLS } from '../../environments/urls';
import { UtilsService } from '../services/utils.service';
import { AwsService } from './aws.service';
import { AuthProviderService } from '../interfaces/auth-provider.interface';
import { environment } from '../../environments/environment';

export interface LoggedInCallback {
    isLoggedIn(message: string, loggedIn: boolean): void;
}

@Injectable({
    providedIn: 'root',
})
export class UserLoginService {
    private authProvider: AuthProviderService;
    private profileSource: BehaviorSubject<User> = new BehaviorSubject<User>(undefined);
    error = new EventEmitter<string>();
    public profile$: Observable<User> = this.profileSource.asObservable();
    batch = false;
    public loginStatusChanged: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private router: Router, private http: HttpClient, private injector: Injector, private utilsService: UtilsService, private awsService: AwsService) {
        this.authProvider = injector.get(environment.auth.provider);
    }

    async init() {
        this.awsService.initAmplify();
        const authenticated = await this.authProvider.init(environment.auth.config);
        if (authenticated) {
            var redirectReversement = this.router.url === '/home';
            this.onLoginSuccess(redirectReversement);
            setInterval(() => {
                this.refreshToken();
            }, 30 * 10000); // 3 minutes
        }
    }

    public refreshToken(): void {
        this.authProvider
            .refreshToken()
            .then((refreshed) => {
                if (refreshed) {
                    this.onLoginSuccess(false);
                }
            })
            .catch((error) => {
                console.error('error refreshing', error);
            });
    }

    public setProfile(response: any): void {
        this.profileSource.next(response);
    }

    public getProfile() {
        return JSON.parse(localStorage.getItem('token'));
    }

    login() {
        this.authProvider.login(environment.auth.loginRedirectUri);
    }

    public setLoginStatus(status: boolean): void {
        this.loginStatusChanged.next(status);
    }

    private onAmplifyLoginSuccess = (user: any, redirect: boolean) => {
        if (redirect) {
            const routerService = this.injector.get(Router);
            const ngZone = this.injector.get(NgZone);
            this.getHabilitations(user.email)
                .then((data) => {
                    if (data) {
                        const response = this.isAuthorized(data);
                        if (response !== null) {
                            ngZone.run(() => {
                                this.setLoginStatus(true);
                                routerService.navigate(['/configuration'], { skipLocationChange: false });
                            });
                        } else {
                            this.setLoginStatus(false);
                            this.error.emit('error authent');
                            this.utilsService.errorNotification("Vous n'avez pas les droits requis pour accéder à Mercure");
                            this.clearProfile();
                        }
                    } else {
                        this.setLoginStatus(false);
                        this.error.emit('error authent');
                        this.utilsService.errorNotification("Erreur d'authentification 2");
                        this.signOut();
                    }
                })
                .catch((err) => {
                    this.setLoginStatus(false);
                    this.error.emit('error authent');
                    this.utilsService.errorNotification("Erreur d'authentification 3");
                    this.signOut();
                });
        } else {
        }
    };

    public onLoginSuccess = async (redirect: boolean = true) => {
        this.setLoginStatus(true);
        // set global user data in store
        const profile = await this.authProvider.getUserInfo();
        const userData = {
            ...profile,
            isLoggedIn: true,
            username: `${profile.firstName} ${profile.lastName}`,
        };
        const token = this.authProvider.getUserToken();
        if (!token) return;

        const expires_at = this.authProvider.getUserTokenExpiration() * 1000;
        const user = {
            email: userData.email,
            name: userData.username,
        };

        localStorage.setItem('token', JSON.stringify({ access_token: token, expires_at, user }));
        // Before signing in, check if already signed in
        Auth.currentAuthenticatedUser()
            .then((currentUser) => {
                if (!currentUser) {
                    this.signInToAmplify(userData, user, redirect);
                } else {
                    this.onAmplifyLoginSuccess(user, redirect);
                }
            })
            .catch((error) => {
                this.signInToAmplify(userData, user, true);
                console.error('Error fetching current authenticated user:', error);
            });
    };

    private signInToAmplify = (userData: any, user: any, redirect: boolean) => {
        try {
            Auth.federatedSignIn(
                environment.auth.federatedSignInProvider,
                {
                    token: this.authProvider.getUserToken(),
                    expires_at: this.authProvider.getUserTokenExpiration(),
                },
                userData
            ).then(
                () => {
                    this.onAmplifyLoginSuccess(user, redirect);
                },
                (error) => {
                    console.error('Failed to sign in to Amplify:', error);
                    this.utilsService.errorNotification('Erreur de connexion au service Amplify');
                }
            );
        } catch (error) {
            console.error('Failed to sign in to Amplify:', error);
            this.utilsService.errorNotification('Erreur de connexion au service Amplify 2');
            this.setLoginStatus(false);
        }
    };

    public onError = (error) => {
        console.error('onError:', error);
        this.error = error;
    };

    public onLogoutSuccess = () => {
        Auth.signOut({ global: true })
            .then(() => {
                this.setLoginStatus(false);
                this.router.navigate(['/']);
            })
            .catch((err) => (this.error = err));
    };

    public checkToken() {
        const token = JSON.parse(localStorage.getItem('token'));

        if (token && token.expires_at - new Date().getTime() <= 0) {
            this.signOut();
        }
    }

    public checkRolesAllowed() {}

    public isAuthenticated(callback?: any): void {
        if (callback == null) {
            throw 'UserLoginService: Callback in isAuthenticated() cannot be null';
        }

        const token = JSON.parse(localStorage.getItem('token'));

        if (token && token.expires_at - new Date().getTime() <= 0) {
            this.utilsService.errorNotification('Utilisateur non connecté', 'Vous devez vous reconnecter. Vous avez perdu la connexion');
            this.signOut();
        }

        if (token && this.batch == true) {
            callback.isLoggedIn('Current user found', true);
        } else {
            Auth.currentAuthenticatedUser()
                .then((user) => {
                    if (user != null) {
                        this.getHabilitations(user.email)
                            .then((data) => {
                                const response = this.isAuthorized(data);
                                // put data in session storage :
                                sessionStorage.setItem('habilitations', JSON.stringify(data));
                                if (response !== null) {
                                    this.setProfile(response);
                                } else {
                                    this.clearProfile();
                                }
                            })
                            .catch((err) => {
                                this.signOut();
                            });
                        callback.isLoggedIn('Current user found', true);
                    } else {
                        callback.isLoggedIn('User not found', false);
                    }
                })
                .catch((err) => {
                    callback.isLoggedIn(err, false);
                });
        }
    }

    public getHabilitations(email: string): Promise<any> {
        const headers = new HttpHeaders().set('Cache-Control', 'no-cache');
        const params = { identifiant: email.split('@')[0] };
        return this.http.get<User>(environment.apiURL + URLS.authent, { params, headers }).toPromise();
    }

    public hasRight(requiredRoles: MercureRoles[]): boolean {
        const result: boolean[] = [];
        requiredRoles.forEach((value) => {
            result.push(this.profileSource.getValue().roles.includes(value));
        });
        return result.includes(true);
    }

    public isAuthorized(data: any) {
        const allowedRoles = [MercureRoles.MERCURE_DEMANDEUR, MercureRoles.CLOT_VALIDATION_ADMIN, MercureRoles.MERCURE_RESPONSABLE, MercureRoles.MERCURE_RESPONSABLE_DELEGUE];

        for (let i = 0; i < data.length; i++) {
            if (data[i].actif === 'OUI' && data[i].groupes.some((role) => allowedRoles.includes(role))) {
                console.info('Vous avez accès à Mercure grâce à votre role :', data[i].groupes.filter((role) => allowedRoles.includes(role)).toString());
                return data[i];
            }
        }
        console.error('Il vous manque un rôle pour accéder à Mercure');
        return null;
    }

    private clearProfile() {
        this.profileSource.next(undefined);
        localStorage.removeItem('token');
        this.router.navigate(['/']);
    }

    public signOut(): void {
        if (this.batch) {
            this.setLoginStatus(false);
            this.clearProfile();
        } else {
            Auth.signOut({ global: true })
                .then(() => {
                    this.setLoginStatus(false);
                    this.clearProfile();
                })
                .catch((error) => this.clearProfile());
        }
    }

    public logoutKeycloak() {
        this.authProvider.logout(environment.auth.logoutRedirectUri);
    }

    public isLoggedIn(): Observable<boolean> {
        return from(Auth.currentAuthenticatedUser()).pipe(
            map((result) => {
                return true;
            }),
            catchError((error) => {
                return of(false);
            })
        );
    }
}
