import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { UserLoginService } from '../services/user-login.service';
import { MercureRoles } from '../enums/role.enum';
import { UtilsService } from '../services/utils.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(public userLoginService: UserLoginService, public router: Router, private utilsService: UtilsService) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.userLoginService.isLoggedIn().pipe(
            switchMap((loggedIn) => {
                // If trying to access the root "/"
                if (state.url === '/') {
                    if (loggedIn) {
                        this.router.navigate(['/configuration']);
                        return of(false);
                    } else {
                        this.router.navigate(['/']);
                        this.utilsService.errorNotification("Vous devez d'abord vous connecter pour accéder à Mercure");
                        return of(false);
                    }
                }

                // If trying to access "/configuration"
                if (state.url.startsWith('/configuration')) {
                    if (state.url.includes('#state=') && state.url.includes('&session_state=') && state.url.includes('&code=')) {
                        return of(true);
                    }
                    if (!loggedIn) {
                        this.router.navigate(['/']);
                        this.utilsService.errorNotification('Vous devez vous connecter pour accéder à cette page');
                        return of(false);
                    }
                    return of(true);
                }

                // If trying to access admin routes "/admin" or "/admin/..."
                if (state.url.startsWith('/admin')) {
                    if (!loggedIn) {
                        this.utilsService.errorNotification('Vous devez vous connecter pour accéder à cette page');
                        this.router.navigate(['/']);
                        return of(false);
                    }
                    return this.userLoginService.getHabilitations(this.userLoginService.getProfile().user.email).then((data) => {
                        const isAdmin = data[0].groupes.some((groupe) => groupe === MercureRoles.ADMINISTRATOR || groupe === MercureRoles.COMPANY_ADMINISTRATOR);
                        if (!isAdmin) {
                            this.router.navigate(['/configuration']);
                            this.utilsService.errorNotification("Vous n'avez pas les droits pour accéder à cette page");
                            return false;
                        }
                        return true;
                    });
                }

                // For all other routes, if user is not logged in, redirect to login
                if (!loggedIn) {
                    this.router.navigate(['/']);
                    this.utilsService.errorNotification('Vous devez vous connecter pour accéder à cette page');
                    return of(false);
                }

                return of(true);
            })
        );
    }
}
