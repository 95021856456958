export const URLS = {
    authent: '/consommateurs/v1/utilisateurs',
    monitoring: {
        file: {
            get: 'fichier',
        },
        comment: {
            get: 'comment',
        },
        file_traite: {
            get: 'fichier_traite',
            lists: 'fichier_traite/lists',
        }
    }
}
