import { Component, OnInit, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { environment } from 'src/environments/environment';
import { UserLoginService } from 'src/app/services/user-login.service';
import { AwsService } from 'src/app/services/aws.service';
import { MercureRoles } from 'src/app/enums/role.enum';

//declare const gapi: any;

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    public isLoggedIn: boolean = undefined;
    public isAdmin: boolean = undefined;
    public auth2: any;
    @Input() module = '';
    public version: string = 'v3.11.0';
    // routes = {
    //     configuration: [
    //         {
    //             url: '/configuration',
    //             label: 'Faire une demande \n de composition',
    //             icon: 'note_add',
    //         },
    //     ],
    //     admin: [
    //         {
    //             url: '/admin',
    //             label: 'Espace administrateur',
    //             icon: 'manage_accounts',
    //         },
    //     ],
    //     support: [
    //         {
    //             url: '/help',
    //             label: 'Aide & Support',
    //             icon: 'trending_up',
    //         },
    //     ],
    // };

    constructor(public router: Router, public dialog: MatDialog, public userLoginService: UserLoginService, public awsService: AwsService) {
        this.userLoginService.loginStatusChanged.subscribe((status: boolean) => {
            this.isLoggedIn = status;
            if (this.isLoggedIn) {
                this.checkIfAdmin();
            }
        });
    }

    ngOnInit() {
        this.loadDynatraceScript();
        this.checkIfAdmin();
        /* this.awsService.initAmplify();
        this.userLoginService.profile$.subscribe((profile) => {
           });
        gapi.load('auth2', () => {
            this.auth2 = gapi.auth2.init({
                client_id: environment.googleClientId,
                cookiepolicy: 'single_host_origin',
                approval_prompt: 'force',
                scope: 'email',
                prompt: 'select_account',
                plugin_name:'App Name that you used in google developer console API'
            })
        }); */
    }

    ngOnChanges() {
        this.checkIfAdmin();
    }

    getProfileName() {
        let data = this.userLoginService.getProfile();
        return data?.user?.name;
    }

    public checkIfAdmin(): void {
        if (this.userLoginService.getProfile() === null) return;
        this.userLoginService.getHabilitations(this.userLoginService.getProfile().user?.email).then((data) => {
            data[0].groupes.forEach((groupe) => {
                if (groupe === MercureRoles.ADMINISTRATOR || groupe === MercureRoles.COMPANY_ADMINISTRATOR) {
                    this.isAdmin = true;
                    this.isLoggedIn = true;
                }
            });
        });
    }

    public getProfileHabilitation(): boolean {
        if (this.isAdmin && this.isLoggedIn) return true;
    }

    public logout(): void {
        this.userLoginService.signOut();
        this.userLoginService.logoutKeycloak();
        this.isLoggedIn = false;
    }

    public login() {
        this.userLoginService.login();
    }

    /**
     * Chargement du script dynatrace
     */
    public loadDynatraceScript() {
        if (environment.dynatraceKey) {
            let node = document.createElement('script');
            node.src = `https://js-cdn.dynatrace.com/jstag/${environment.dynatraceKey}_complete.js`;
            node.type = 'text/javascript';
            node.crossOrigin = 'anonymous';
            document.getElementsByTagName('body')[0].appendChild(node);
        }
    }
}
