import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-validation-component',
    styleUrls: ['./confirm-validation.component.scss'],
    templateUrl: './confirm-validation.component.html',
})
export class ConfirmValidationDialog {
    constructor(public dialogRef: MatDialogRef<ConfirmValidationDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    saveChanges(): void {
        this.dialogRef.close({ event: 'saveChanges' });
    }
}
