<div class="content"  style="opacity: 0.4;">
    <h6>Type d'envoi</h6>
    <div class="flex">
        <div class="option" [ngClass]="{'selected': typeSelected == 'direct'}"
             (click)="typeSelected = 'direct'">
            <mat-icon *ngIf="typeSelected == 'direct'">check</mat-icon>
            <div> Envoi direct </div>
        </div>
        <div class="option" [ngClass]="{'selected': typeSelected == 'difer'}"
             (click)="typeSelected = 'difer'">
            <mat-icon *ngIf="typeSelected == 'difer'">check</mat-icon>
            <div> Envoi différé </div>
        </div>
    </div>
    <ng-container *ngIf="typeSelected == 'difer'">
        <h6>Options d'envoi</h6>
        <div class="flex">
            <form>
                <label>Date d'envoi</label>
                <mat-form-field appearance="fill">
                    <input matInput [matDatepicker]="picker" [formControl]="date" [min]="minDate">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
            </form>
            <form>
                <label>Heure d'envoi</label>
                <ngx-timepicker-field [format]="24" [formControl]="time" [defaultTime]="'8:00 am'"
                                      [min]="'8:00 am'"
                                      [max]="'7:00 pm'"></ngx-timepicker-field>
            </form>
        </div>
    </ng-container>
    <div class="button-group">
        <button mat-raised-button class="btn-previuos" (click)="previous()">
            <mat-icon>chevron_left</mat-icon>
            Précèdent
        </button>
        <button mat-stroked-button class="btn-next" (click)="next()"  [disabled]="isReady()">
            Suivant
        </button>
    </div>
</div>
