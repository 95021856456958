
export const filters = {
    generalUseCase: [{
        type: 'chips',
        title: 'Code Exploitation',
        placeholder: '345678989654'
    },{
        type: 'chips',
        title: 'Code Territoire',
        placeholder: '345678989654'
    },{
        type: 'chips',
        title: 'Libellé Territoire',
        placeholder: '345678989654'
    },{
        type: 'chips',
        title: 'Code Contrat',
        placeholder: '345678989654'
    },{
        type: 'chips',
        title: 'Libellé Contrat',
        placeholder: '345678989654'
    },{
        type: 'chips',
        title: 'Code Traité',
        placeholder: '345678989654'
    },{
        type: 'chips',
        title: 'Code Commune',
        placeholder: '345678989654'
    },{
        type: 'chips',
        title: 'Numero Abonnement',
        placeholder: '345678989654'
    }],
    relaunch: [
        {
            type: 'text',
            title: 'Lot de facturation',
            placeholder: '345678989654'
        },
        {
            type: 'select',
            default: '',
            required: false,
            title: "Code Activité",
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            placeholder: ''
        },
        {
            type: 'select',
            default: '',
            required: false,
            title: "Code VIP",
            data: [1, 2, 3, 4, 5, 6, 7, 8, 9],
            placeholder: ''
        },
        {
            type: 'select',
            default: '',
            required: false,
            title: "Stade Relance",
            data: [10, 20, 30, 68, 75, 84, 91, 92, 93],
            placeholder: ''
        },
        {
            type: 'number',
            default: '',
            required: false,
            title: "Montant Solde TTC",
            placeholder: 350
        },
        {
            type: 'date',
            default: '',
            required: false,
            title: "Date Emission Facture",
            placeholder: ''
        }
    ],
    replace: [
        {
            type: 'text',
            title: 'Lot de facturation',
            placeholder: '345678989654'
        }
    ]
};

