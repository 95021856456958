import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-canaux-ecartes',
    templateUrl: './confirm-canaux-ecartes.component.html',
    styleUrls: ['./confirm-canaux-ecartes.component.scss'],
})
export class ConfirmCanauxEcartesComponent implements OnInit {
    public message: string;

    constructor(public dialogRef: MatDialogRef<ConfirmCanauxEcartesComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

    ngOnInit() {
        if (this.data && this.data.canauxEcartes) {
            this.message = Object.entries(this.data.canauxEcartes)
                .map(([support, codes]) => `Le canal ${this.getSupportLabel(support)} a été écarté pour les contrats n°<strong>${(codes as string[]).join('</strong> et <strong>')}</strong>.`)
                .join(' ');
        }
    }

    getSupportLabel(supportCode: string): string {
        switch (supportCode) {
            case 'S':
                return 'SMS';
            case 'M':
                return 'Mail';
            case 'C':
                return 'Courrier';
            default:
                return supportCode;
        }
    }

    standardSendCommunication(): void {
        this.dialogRef.close({ event: 'standardSend' });
    }

    forceSendCommunication(): void {
        this.dialogRef.close({ event: 'forceSend' });
    }
}
