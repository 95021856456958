import Keycloak from "keycloak-js";
import { Injectable } from "@angular/core";
import { AuthProviderService } from "../interfaces/auth-provider.interface";

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void;
}

@Injectable({ providedIn: "root" })
export class KeycloakAuthService implements AuthProviderService {
  keycloak: Keycloak;

  constructor() {}

  public async init(options: any) {
    const { config, initOptions } = options;
    this.keycloak = new Keycloak(config);
    const authenticated = await this.keycloak.init(initOptions);
    return authenticated;
  }

    public listenKeycloakIframeSession = () => {
        
        window.addEventListener('message', (event) => {
            
            // Check the origin for security reasons
            if (event.origin !== 'http://localhost:4200') {
                return;
            }
            // Process the authentication data from the iframe
            const authData = event.data;
            if (authData.isAuthenticated) {
                // Handle the authenticated state
            } else {
                // Handle the unauthenticated state
            }
        });
    };

  login(redirectUri?: string): any {
    const options = {
      redirectUri,
    };
    this.keycloak.login(options);
  }

  async logout(redirectUri?: string) {
    const options = {
      redirectUri,
    };
    await this.keycloak.logout(options);
  }

  public async refreshToken(): Promise<any> {
    return this.keycloak.updateToken(60);
  }

  public async getUserInfo(): Promise<any> {
    return this.keycloak.loadUserProfile();
  }

  isAuthenticated(): Promise<boolean> {
    throw new Error("Method not implemented.");
  }

  getUserToken(): string {
    return this.keycloak.token;
  }

  getUserTokenExpiration(): number {
    return this.keycloak.tokenParsed.exp;
  }
}
