<mat-spinner *ngIf="loading" class="connexion-loading"></mat-spinner>
<div class="page-layout">
    <div class="admin-layout">
        <h1 class="color-blue main-title message-layout">
            Gestion des messages du carrousel
        </h1>
        <div class="sentences-form-container" *ngFor="let sentence of displayedSentences; let i = index;">
            <form class="form-sentence">
                <mat-form-field class="form-sentence-content" appearance="fill">
                    <mat-label>Message</mat-label>
                    <textarea
                        class="textarea-message"
                        [value]="sentence"
                        matInput
                        #message 
                        maxlength="200" 
                        cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize"
                        cdkAutosizeMinRows="1"
                        cdkAutosizeMaxRows="5"
                      ></textarea>
                    <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
                </mat-form-field>
                <button mat-raised-button type="submit" color="primary" class="update-button" (click)="updateMessage(message.value, i)">Modifier</button>
                <button mat-raised-button type="submit" color="warn" class="delete-button" (click)="deleteMessage(i)">Supprimer</button>
            </form>
        </div>
        <mat-divider *ngIf="displayedSentences.length"></mat-divider>
        <div class="sentences-form-container">
            <form *ngIf="displayedSentences.length < 8" class="form-sentence">
                <mat-form-field class="form-sentence-content" appearance="fill">
                  <mat-label>Message</mat-label>
                  <textarea
                    id="addMessage"
                    matInput
                    #message
                    maxlength="200" 
                    cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize"
                    cdkAutosizeMinRows="1"
                    cdkAutosizeMaxRows="5"></textarea>
                  <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
                </mat-form-field>
                <button mat-raised-button color="primary" class="add-button" (click)="addMessage(message.value)">Ajouter un commentaire</button>
            </form>      
        </div>
    </div>
</div>