import tinymce from 'tinymce';
import { Storage } from 'aws-amplify';
import { Component, OnDestroy, OnInit } from '@angular/core';

import { AwsService } from 'src/app/services/aws.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-help-and-support',
    templateUrl: './help-and-support.component.html',
    styleUrls: ['./help-and-support.component.scss'],
})
export class HelpAndSupportComponent implements OnInit, OnDestroy {
    public loading = false;
    public richText: any = {
        menubar: false,
        base_url: '/tinymce',
        suffix: '.min',
        skin: 'tinymce-5',
        toolbar: 'undo redo | fontfamily fontsize | forecolor | bold italic underline strikethrough | link | align | checklist numlist bullist indent outdent',
        plugins: 'link lists wordcount',
        height: 480,
        font_family_formats: 'Arial=arial;',
        font_size_formats: '10px 12px 13px 14px 15px 16px 18px 20px',
        visibility: 'visible',
        font_css: ['https://fonts.googleapis.com/css2?family=Afacad:wght@500&family=Marcellus&family=Poppins:wght@300&family=Raleway:wght@100&display=swap'],
    };
    public retryCount = 0;

    constructor(private utilsService: UtilsService, private awsService: AwsService) {}

    ngOnInit() {
        setTimeout(() => {
            this.loadContent();
        }, 100);
    }

    ngOnDestroy() {
        const editor = tinymce.get('richTextEditor');
        if (editor) {
            editor.remove();
        }
    }

    public async loadContent() {
        this.loading = true;
        const fileName = 'help-and-support-page.html';
        Storage.get(fileName, { download: true, expires: 10, cacheControl: 'no-cache' })
            .then((result) => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    this.utilsService.successNotification("Contenu de la page d'aide et de support récupéré avec succès.");
                    if (tinymce.get('richTextEditor')) {
                        tinymce.get('richTextEditor').setContent(event.target.result);
                    }
                };
                reader.readAsText((result as any).Body);
                this.loading = false;
            })
            .catch((err) => {
                if (this.retryCount < 3) {
                    this.retryCount++;
                    setTimeout(() => {
                        this.loadContent();
                    }, 500);
                } else {
                    this.utilsService.errorNotification("La récupération du contenu de la page d'aide et de support a échouée.", err);
                    console.error('Erreur: ', err);
                    this.loading = false;
                }
            });
    }

    public saveContent() {
        if (tinymce.get('richTextEditor')) {
            const content = tinymce.get('richTextEditor').getContent();
            const fileName = 'help-and-support-page.html';
            Storage.put(fileName, content, {
                contentType: 'text/html',
            })
                .then((result) => {
                    this.utilsService.successNotification('Le contenu a été sauvegardé avec succès.');
                })
                .catch((err) => {
                    this.utilsService.errorNotification('La sauvegarde du contenu a échouée.', err);
                    console.error(err);
                });
        } else {
            this.utilsService.errorNotification("L'éditeur de texte n'est pas initialisé.");
        }
    }
}
