<div class="page-layout">
    <div class="content content-layout">
        <div *ngIf="formattedMessage">
            <br>
            <div class="accueil-message" [style.backgroundColor]="apiResponse.color">
                <div [innerHTML]="formattedMessage" [style.color]="apiResponse.textColor" class="formatted-text"></div>
            </div>
            <div>
                <section class="triangle"></section>
                <img src="assets/img/bulle-guy.svg" alt="" width="100px">
            </div>
        </div>

        <app-steps [step]="step"></app-steps>

        <!-- Step 1 : Import file -->
        <app-recipients *ngIf="step == 1" [records]="records" (nextStep)="next($event, 1)"
            [selectedIndex]="selectedIndex" [displayedNameColumns]="displayedNameColumns" [headers]="headers"
            (missingCanaux)="saveMissingCanaux($event)" [displayCodeContratWarning]="displayCodeContratWarning"
            [drive]="drive"></app-recipients>

        <!-- Step 2 : Settings des destinataires -->
        <app-settings-send *ngIf="step == 2" (nextStep)="next($event)" [isHorsIris]="isHorsIris"
            (previousStep)="previous($event)" [com]="com" [displayedNameColumns]="displayedNameColumns"
            [headers]="headers" [missingCanaux]="missingCanaux" [selectedIndex]="selectedIndex"
            [activateSecondField]="activateSecondField" [activateThirdField]="activateThirdField"
            [selectedRegion]="selectedRegion" [selectedTerritoire]="selectedTerritoire" [drive]="drive"
            [displayCodeContratWarning]="displayCodeContratWarning"></app-settings-send>

        <!-- Step 3 : Synthèse d'envoi -->
        <app-resume *ngIf="step == 3" [com]="com" (previousStep)="previous($event)"
            [isHorsIris]="isHorsIris"></app-resume>
    </div>
</div>