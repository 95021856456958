import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

import { useCase } from 'src/app/data/useCase';
import { AwsService } from 'src/app/services/aws.service';
import { UtilsService } from 'src/app/services/utils.service';
import { UserLoginService } from 'src/app/services/user-login.service';
import { ConfirmValidationDialog } from './confirm-validation/confirm-validation.component';
import { ConfirmCanauxEcartesComponent } from './confirm-canaux-ecartes/confirm-canaux-ecartes.component';

interface CasUsage {
    value: string;
    label: string;
}

const CAS_USAGES = {
    'rel-imp': {
        'label': 'Relance amiable des impayés',
        'params': {
            'support1': 'M',
            'support2': 'S',
            'disabledSupport3': true,
            'selectedRecipientTypes': {
                'abonne': false,
                'destinataire': true,
            },
            'actif': true,
            'resiled': true,
            'aboned': true,
            'closed': true,
            'fillabo': false,
            'isDateSeted': true,
            'staggerCommunications': true,
            'dateSo': new Date(new Date().setDate(new Date().getDate() + 1)),
        },
    },
    'int-compt': {
        'label': 'Intervention sur compteur',
        'params': {
            'support1': 'M',
            'support2': 'S',
            'support3': 'C',
            'selectedRecipientTypes': {
                'abonne': true,
                'destinataire': false,
            },
            'actif': true,
            'resiled': true,
            'aboned': true,
            'closed': true,
            'fillabo': true,
        },
    },
    'rel-index': {
        'label': 'Campagne relevé index',
        'params': {
            'support1': 'M',
            'support2': 'S',
            'support3': 'C',
            'selectedRecipientTypes': {
                'abonne': true,
                'destinataire': false,
            },
            'actif': true,
            'resiled': true,
            'aboned': true,
            'closed': true,
            'fillabo': true,
        },
    },
    'alerte-conso': {
        'label': 'Alerte sur consommation',
        'params': {
            'support1': 'M',
            'support2': 'C',
            'disabledSupport3': true,
            'selectedRecipientTypes': {
                'abonne': false,
                'destinataire': true,
            },
            'actif': true,
            'resiled': true,
            'aboned': true,
            'closed': true,
            'fillabo': true,
        },
    },
};

@Component({
    selector: 'app-settings-send',
    templateUrl: './settings-send.component.html',
    styleUrls: ['./settings-send.component.scss'],
})
export class SettingsSendComponent implements OnInit {
    casUsages: CasUsage[] = [
        {
            value: 'autre',
            label: 'Autre',
        },
        {
            value: 'rel-imp',
            label: 'Relance amiable des impayés',
        },
        {
            value: 'int-compt',
            label: 'Intervention sur compteur',
        },
        {
            value: 'rel-index',
            label: 'Campagne relevé index',
        },
        {
            value: 'alerte-conso',
            label: 'Alerte sur consommation',
        },
    ];

    public casUsageChoisi: string = 'autre';
    public marqueReferentiel: string = ''; /* @TODO: à supprimer ultérieurement */

    public regionsTerritoires = {
        '62': [
            { 'code': '620', 'libelle_region': 'Est', 'libelle': 'Direction Région Est' },
            { 'code': '62A', 'libelle_region': 'Est', 'libelle': 'Alsace' },
            { 'code': '62E', 'libelle_region': 'Est', 'libelle': 'Lorraine Sud Haute Marne' },
            { 'code': '62F', 'libelle_region': 'Est', 'libelle': 'Champagne Ardenne' },
            { 'code': '62G', 'libelle_region': 'Est', 'libelle': 'Metz Thionville' },
            { 'code': '62H', 'libelle_region': 'Est', 'libelle': 'Moselle Est' },
            { 'code': '62I', 'libelle_region': 'Est', 'libelle': 'Verdun - Pays Haut' },
            { 'code': '62K', 'libelle_region': 'Est', 'libelle': 'Franche Comté' },
        ],
        '63': [
            { 'code': '630', 'libelle_region': 'Centre Est', 'libelle': 'Direction Région Centre Est' },
            { 'code': '63A', 'libelle_region': 'Centre Est', 'libelle': 'Bourgogne Centre' },
            { 'code': '63B', 'libelle_region': 'Centre Est', 'libelle': 'Drome Ardèche' },
            { 'code': '63C', 'libelle_region': 'Centre Est', 'libelle': 'EGL' },
            { 'code': '63D', 'libelle_region': 'Centre Est', 'libelle': 'Haute-Savoie Ain Jura' },
            { 'code': '63E', 'libelle_region': 'Centre Est', 'libelle': 'Loire Auvergne' },
            { 'code': '63G', 'libelle_region': 'Centre Est', 'libelle': 'Saône et Loire' },
            { 'code': '63H', 'libelle_region': 'Centre Est', 'libelle': 'Isère - Savoie' },
            { 'code': '63I', 'libelle_region': 'Centre Est', 'libelle': 'Rhône Saône Confluence' },
            { 'code': '63J', 'libelle_region': 'Centre Est', 'libelle': 'Nord Bourgogne' },
            { 'code': '63T', 'libelle_region': 'Centre Est', 'libelle': 'CHAPUIS' },
        ],
        '64': [
            { 'code': '640', 'libelle_region': 'Sud Ouest', 'libelle': 'Direction Région Sud Ouest' },
            { 'code': '64A', 'libelle_region': 'Sud Ouest', 'libelle': 'Atlantique' },
            { 'code': '64B', 'libelle_region': 'Sud Ouest', 'libelle': 'Dordogne - Limousin' },
            { 'code': '64C', 'libelle_region': 'Sud Ouest', 'libelle': 'Garonne et affluents' },
            { 'code': '64D', 'libelle_region': 'Sud Ouest', 'libelle': 'Pyrénées Gascogne' },
            { 'code': '64E', 'libelle_region': 'Sud Ouest', 'libelle': 'Toulouse Pyrénées' },
            { 'code': '64F', 'libelle_region': 'Sud Ouest', 'libelle': 'Bordeaux Aquitaine' },
            { 'code': '64G', 'libelle_region': 'Sud Ouest', 'libelle': 'Toulouse Métropole' },
            { 'code': '64T', 'libelle_region': 'Sud Ouest', 'libelle': 'C2EA' },
        ],
        '65': [
            { 'code': '650', 'libelle_region': 'Centre Ouest', 'libelle': 'Direction Région Centre Ouest' },
            { 'code': '65A', 'libelle_region': 'Centre Ouest', 'libelle': 'Vendée Atlantique' },
            { 'code': '65B', 'libelle_region': 'Centre Ouest', 'libelle': 'Armor Ille Emeraude' },
            { 'code': '65C', 'libelle_region': 'Centre Ouest', 'libelle': 'Beauce Sologne Berry' },
            { 'code': '65D', 'libelle_region': 'Centre Ouest', 'libelle': 'Bretagne Ouest' },
            { 'code': '65E', 'libelle_region': 'Centre Ouest', 'libelle': 'Bassin de la Vilaine' },
            { 'code': '65F', 'libelle_region': 'Centre Ouest', 'libelle': 'Loire Atlantique' },
            { 'code': '65G', 'libelle_region': 'Centre Ouest', 'libelle': 'Sarthe Mayenne Anjou' },
            { 'code': '65H', 'libelle_region': 'Centre Ouest', 'libelle': 'Val de Loire Sologne' },
            { 'code': '65I', 'libelle_region': 'Centre Ouest', 'libelle': 'Vendée' },
        ],
        '66': [
            { 'code': '660', 'libelle_region': 'Hauts de France', 'libelle': 'Direction Région Hauts de France' },
            { 'code': '66A', 'libelle_region': 'Hauts de France', 'libelle': 'Aisne' },
            { 'code': '66B', 'libelle_region': 'Hauts de France', 'libelle': 'Artois Douaisis' },
            { 'code': '66C', 'libelle_region': 'Hauts de France', 'libelle': 'Artois Hainaut' },
            { 'code': '66D', 'libelle_region': 'Hauts de France', 'libelle': 'Bruay Béthune' },
            { 'code': '66E', 'libelle_region': 'Hauts de France', 'libelle': 'Lille Métropole' },
            { 'code': '66F', 'libelle_region': 'Hauts de France', 'libelle': 'Littoral Audomarois' },
            { 'code': '66G', 'libelle_region': 'Hauts de France', 'libelle': 'Oise' },
            { 'code': '66H', 'libelle_region': 'Hauts de France', 'libelle': 'Somme' },
        ],
        '67': [
            { 'code': '670', 'libelle_region': 'Ile de France', 'libelle': 'Direction Région Ile de France' },
            { 'code': '67A', 'libelle_region': 'Ile de France', 'libelle': 'Cergy Vexin' },
            { 'code': '67B', 'libelle_region': 'Ile de France', 'libelle': 'Marne et Oise' },
            { 'code': '67C', 'libelle_region': 'Ile de France', 'libelle': 'Paris Métropole' },
            { 'code': '67D', 'libelle_region': 'Ile de France', 'libelle': 'Essonne' },
            { 'code': '67E', 'libelle_region': 'Ile de France', 'libelle': 'Seine et Marne' },
            { 'code': '67F', 'libelle_region': 'Ile de France', 'libelle': 'Yvelines' },
        ],
        '68': [
            { 'code': '680', 'libelle_region': 'Normandie', 'libelle': 'Direction Région Normandie' },
            { 'code': '68B', 'libelle_region': 'Normandie', 'libelle': 'Calvados' },
            { 'code': '68C', 'libelle_region': 'Normandie', 'libelle': 'Dieppe Caux Pays De Bray' },
            { 'code': '68D', 'libelle_region': 'Normandie', 'libelle': 'Eure Pays de Bray' },
            { 'code': '68E', 'libelle_region': 'Normandie', 'libelle': 'Manche et Orne' },
            { 'code': '68F', 'libelle_region': 'Normandie', 'libelle': 'Seine Maritime' },
        ],
        '69': [
            { 'code': '690', 'libelle_region': 'Méditerranée', 'libelle': 'Direction Région Méditerranée' },
            { 'code': '69A', 'libelle_region': 'Méditerranée', 'libelle': 'Provence Alpes' },
            { 'code': '69B', 'libelle_region': 'Méditerranée', 'libelle': 'Alpes Maritimes' },
            { 'code': '69D', 'libelle_region': 'Méditerranée', 'libelle': 'Corse' },
            { 'code': '69E', 'libelle_region': 'Méditerranée', 'libelle': 'Esterel' },
            { 'code': '69F', 'libelle_region': 'Méditerranée', 'libelle': 'Golfe de Saint Tropez' },
            { 'code': '69G', 'libelle_region': 'Méditerranée', 'libelle': 'Var - Provence Méditerranée' },
            { 'code': '69H', 'libelle_region': 'Méditerranée', 'libelle': 'Vaucluse' },
        ],
        '70': [
            { 'code': '700', 'libelle_region': 'Sud', 'libelle': 'Direction Région Sud' },
            { 'code': '70A', 'libelle_region': 'Sud', 'libelle': 'Aude' },
            { 'code': '70B', 'libelle_region': 'Sud', 'libelle': 'Pyrénées Orientales' },
            { 'code': '70D', 'libelle_region': 'Sud', 'libelle': 'Gard Lozère' },
            { 'code': '70F', 'libelle_region': 'Sud', 'libelle': 'Hérault' },
            { 'code': '70H', 'libelle_region': 'Sud', 'libelle': 'Aveyron' },
            { 'code': '70P', 'libelle_region': 'Sud', 'libelle': 'Perpignan Méditerranée Métropole' },
            { 'code': '70Q', 'libelle_region': 'Sud', 'libelle': 'Nîmes Métropole' },
        ],
    };

    useCase = useCase;
    selectedTheme: any;
    selectedThemeLabel: string;
    selectedCase: any;
    selectedCaseLabel: string;
    cases: any[];
    public support1 = '';
    public support2 = '';
    public support3 = '';
    disabledSupport3: boolean = false;
    affranchissement = 'EGC';
    supportList1 = [];
    supportList2 = [];
    records = [];
    public selectedRecipientTypes: { [key: string]: boolean } = { abonne: false, destinataire: true };
    recipientTypes: string[] = ['abonne', 'destinataire'];
    recipientLabels = {
        'abonne': 'Abonné',
        'destinataire': 'Destinataire de facture',
    };
    toolTips = {
        'abonne': 'Le profil abonné dans IRIS qui correspond à la personne qui habite le logement',
        'destinataire': 'Le profil payeur dans IRIS qui correspond à la personne qui reçoit les factures',
    };
    fillabo = false;
    actif = true;
    resiled = true;
    aboned = true;
    closed = true;
    echantOk = true;
    echantko = false;
    supports = [
        { key: 'S', label: 'SMS' },
        { key: 'M', label: 'Mail' },
        { key: 'C', label: 'Courrier' },
    ];
    postages = [
        { key: 'EGC', label: 'Standard' },
        { key: 'ELS', label: 'Lettre Suivie' },
        { key: 'RRA', label: 'Recommandé avec accusé de réception' },
        { key: 'RRS', label: 'Recommandé sans accusé de réception' },
    ];
    jsondata = {};
    data = [];
    fileObj: File;
    errorMsg: boolean;
    loading = 'init';
    isLoading: boolean = false;
    isDateSeted = true;
    dateSo = new Date();
    staggerCommunications = false; // Stagger the sending of communications (emails and sms)
    dateEmision = new Date();
    priceSupport: any = {
        prix_C_EGC: 0.853,
        prix_C_ELS: 1.99,
        prix_C_ETR: 2.26,
        prix_C_RRA: 7.47,
        prix_C_RRS: 5.375,
        prix_M: 0.06,
        prix_S: 0.128,
    };
    @Output() nextStep = new EventEmitter<any>();
    @Output() previousStep = new EventEmitter<any>();
    @Input() isHorsIris: boolean = false;
    @Input() missingCanaux: any[] = [];
    @Input() headers: any;
    @Input() displayedNameColumns = [];
    public removedCanaux: any[] = [];
    @Input() public drive: any;
    @Input() set com(val: any) {
        this.support1 = val.support1;
        this.selectedRecipientTypes = val.selectedRecipientTypes || { abonne: false, destinataire: true };
        this.updateListSupport1(this.support1);
        this.support2 = val.support2;
        this.updateListSupport2(this.support2);
        this.support3 = val.support3;
        this.actif = val.actif || true;
        this.aboned = val.aboned || true;
        this.closed = val.closed || true;
        this.resiled = val.resiled || true;
        this.affranchissement = val.affranchissement || 'EGC';
        this.isDateSeted = !!val.isDateSeted;
        if (this.isDateSeted) this.date = new FormControl(val.date);
        if (val.records) this.records = val.records;
    }
    @Input() public activateSecondField: boolean = false;
    @Input() public activateThirdField: boolean = false;
    @Input() public selectedRegion: string;
    @Input() public selectedTerritoire: string;
    @Input() public displayCodeContratWarning: boolean = false;
    public territoiresFiltres: string[] = [];

    public settingsArray = [];
    public date = new FormControl(new Date());
    public heureEmission = new FormControl(`${new Date().getHours()}:${new Date().getMinutes()}`);
    public minDate = new Date();
    public maxDate = new Date();

    constructor(public dialog: MatDialog, public userLoginService: UserLoginService, public awsService: AwsService, public datepipe: DatePipe, private utilsService: UtilsService) {
        let date = new Date();
        this.maxDate.setDate(date.getDate() + 7);
    }

    ngOnInit(): void {
        this.awsService.priceSupport().subscribe((res) => {
            if (res && res.prix_par_canal) {
                this.priceSupport = res.prix_par_canal;
                Object.keys(res.prix_par_canal).forEach((elmt) => {
                    this.priceSupport['prix_' + elmt] = res.prix_par_canal[elmt];
                });
            }
        });
        if (this.missingCanaux.includes('email_destinataire')) {
            this.supports = this.supports.filter((support) => support.key !== 'M');
            this.removedCanaux.push('Email');
        }
        if (this.missingCanaux.includes('telephone_destinataire')) {
            this.supports = this.supports.filter((support) => support.key !== 'S');
            this.removedCanaux.push('SMS');
        }
        if (this.missingCanaux.includes('afnor1, afnor4, afnor6, afnor7')) {
            this.supports = this.supports.filter((support) => support.key !== 'C');
            this.removedCanaux.push('Courrier');
        }
    }

    resetCommunicationFields() {
        this.support1 = '';
        this.support2 = '';
        this.support3 = '';
        this.disabledSupport3 = false;
        this.affranchissement = 'EGC';
    }

    updateCasdUsage(casUsageChoisi) {
        this.resetCommunicationFields();
        Object.entries(CAS_USAGES[casUsageChoisi].params).forEach(([propertyName, value]) => {
            this.updateProperty(propertyName, value);
        });
    }

    updateProperty(propertyName, newValue) {
        if (!this.hasOwnProperty(propertyName)) return;
        this[propertyName] = newValue;
        if (propertyName === 'support1' && newValue !== 'C') {
            this.updateListSupport1(newValue);
        }
        if (propertyName === 'support2' && newValue !== 'C') {
            this.updateListSupport2(newValue);
        }
    }

    reset(order) {
        let supports = [this.support1, this.support2, this.support3];
        switch (order) {
            case 1:
                if (this.support1 === 'C') {
                    this.onChangeAffranchissement('EGC');
                }
                this.support1 = supports[1];
                this.support2 = supports[2];
                this.support3 = '';
                this.updateListSupport1(this.support1);
                this.updateListSupport2(this.support2);
                this.support2 = supports[2];
                break;
            case 2:
                if (this.support2 === 'C') {
                    this.onChangeAffranchissement('EGC');
                }
                this.support2 = supports[2];
                this.support3 = '';
                this.updateListSupport2(this.support2);
                break;
            case 3:
                if (this.support3 === 'C') {
                    this.onChangeAffranchissement('EGC');
                }
                this.support3 = '';
                break;
        }
    }

    next(idlot, timeLeft = this.timeLeft, emissionHours = []) {
        this.settingsArray.push({
            idlot,
            lastStepInterval: this.interval,
            timeLeft,
            affranchissement: this.affranchissement,
            support1: this.support1,
            support2: this.support2,
            support3: this.support3,
            selectedRecipientTypes: this.selectedRecipientTypes,
            actif: this.actif,
            aboned: this.aboned,
            closed: this.closed,
            resiled: this.resiled,
            isDateSeted: this.isDateSeted,
            staggerCommunications: this.staggerCommunications,
            emissionHours: emissionHours,
            date: this.dateEmision || new Date(),
            heureEmission: this.heureEmission,
            isSecondFieldActive: this.activateSecondField,
            isThirdFieldActive: this.activateThirdField,
            selectedRegion: this.selectedRegion,
            selectedTerritoire: this.selectedTerritoire,
            displayedNameColumns: this.displayedNameColumns,
        });
        this.nextStep.emit(this.settingsArray[0]);
    }

    save() {
        this.nextStep.emit({
            support1: this.support1,
            support2: this.support2,
            support3: this.support3,
            selectedCase: this.selectedCase,
            selectedTheme: this.selectedTheme,
            selectedThemeLabel: this.selectedThemeLabel,
            selectedCaseLabel: this.selectedCaseLabel,
            selectedRecipientTypes: this.selectedRecipientTypes,
        });
    }

    previous() {
        const previousSettings = {
            records: this.records,
            selectedIndex: this.isHorsIris ? 1 : 0,
            displayedNameColumns: this.displayedNameColumns,
            headers: this.headers,
            drive: this.drive,
            displayCodeContratWarning: this.displayCodeContratWarning,
        };
        this.previousStep.emit(previousSettings);
    }

    selectTheme(val) {
        if (val.selectedTheme) {
            this.selectedTheme = val.selectedTheme;
            this.selectedThemeLabel = val.selectedThemeLabel;
            this.cases = this.useCase[val.selectedTheme].cases;
            if (val.selectedCase) {
                let caseFonded = this.cases.find((item) => {
                    return item.key == val.selectedCase;
                });
                this.selectCase(caseFonded);
                this.support1 = val.support1;
                this.updateListSupport1(this.support1);
                this.support2 = val.support2;
                this.updateListSupport2(this.support2);
                this.support3 = val.support3;
            }
        } else {
            this.selectedTheme = val.key;
            this.selectedThemeLabel = val.value.label;
            this.cases = this.useCase[val.key].cases;
            if (this.cases?.length == 1) {
                this.selectCase(this.cases[0]);
            } else {
                this.selectedCase = '';
                this.postages = [];
                this.support1 = '';
                this.support2 = '';
                this.support3 = '';
            }
        }
    }

    selectCase(item) {
        this.selectedCase = item.key;
        this.selectedCaseLabel = item.label;
        this.postages = item.postages;
        this.supports = item.supports;
        this.support1 = item.supports[0].key;
        this.updateListSupport1(item.supports[0].key);
        this.support2 = item.supports[1].key;
        this.updateListSupport2(item.supports[1].key);
        if (item.supports?.length > 2) {
            this.support3 = item.supports[2].key;
        }
    }

    onRegionSelect(regionCode: string) {
        this.selectedTerritoire = null;
        this.territoiresFiltres = this.regionsTerritoires[regionCode].map((item) => item.libelle);
    }

    getPriceffranchissement() {
        switch (this.affranchissement) {
            case 'EGC':
                return '( Prix unitaire : ' + this.priceSupport['prix_C_EGC'] + '€ )';
            case 'ELS':
                return '( Prix unitaire : ' + this.priceSupport['prix_C_ELS'] + '€ )';
            case 'RRS':
                return '( Prix unitaire : ' + this.priceSupport['prix_C_RRS'] + '€ )';
            case 'RRA':
                return '( Prix unitaire : ' + this.priceSupport['prix_C_RRA'] + '€ )';
            default:
                return '';
        }
    }

    getPriceunit(value) {
        switch (value) {
            case 'C':
                return this.getPriceffranchissement();
            case 'S':
                return '( Prix unitaire : ' + this.priceSupport['prix_S'] + '€ )';
            case 'M':
                return '( Prix unitaire : ' + this.priceSupport['prix_M'] + '€ )';
            default:
                return '';
        }
    }

    updateListSupport1(key) {
        this.support3 = '';
        this.onChangeAffranchissement('EGC');
        this.supportList1 = this.supports.filter((item) => {
            return item.key !== key;
        });
        if (this.support2 !== '') {
            this.supportList2 = this.supportList1.filter((item) => {
                return item.key !== key;
            });
        } else {
            this.support2 = '';
            this.supportList2 = [];
        }
    }

    updateListSupport2(key) {
        this.support3 = '';
        if (this.support1 !== 'C') {
            this.onChangeAffranchissement('EGC');
        }
        this.supportList2 = this.supportList1.filter((item) => {
            return item.key !== key;
        });
    }

    isReady() {
        if (this.isHorsIris) {
            return !(this.support1 && (this.echantOk || this.echantko) && this.selectedTerritoire && this.selectedRegion);
        }
        return !(this.support1 && this.selectedRecipientTypes && (this.echantOk || this.echantko) && (this.actif || this.resiled || this.aboned || this.closed));
    }

    onFilePicked(event: Event): void {
        this.errorMsg = false;
        const FILE = (event.target as HTMLInputElement).files[0];
        this.fileObj = FILE;
    }

    timeLeft: number = 30;
    duration: number = 30;
    interval;
    displayTimeString;

    startTimer() {
        this.interval = setInterval(() => {
            if (this.timeLeft > 0) {
                this.timeLeft--;
                this.displayTime();
            } else {
                this.timeLeft = 15; // Still processing ? Add 15 seconds
            }
        }, 1000);
    }

    displayTime() {
        let minutes = Math.floor(this.timeLeft / 60);
        let seconds = this.timeLeft % 60;
        this.displayTimeString = '';

        if (minutes > 0) {
            this.displayTimeString = `${minutes}m ${seconds}s`;
        } else {
            this.displayTimeString = `${seconds}s`;
        }
    }

    stopTimer() {
        clearInterval(this.interval);
        this.timeLeft = 0;
    }

    setErrorLoading() {
        this.loading = 'error';
        this.stopTimer();
    }

    public onChangeAffranchissement(typeAffranchissement?: string) {
        this.affranchissement = typeAffranchissement || this.affranchissement;
    }

    // Vérifie si l'utilisateur a coché les lettres recommandées
    public checkRegisterLetter() {
        if (['RRA', 'RRS'].indexOf(this.affranchissement) !== -1) {
            const dialogRef = this.dialog.open(ConfirmValidationDialog, {
                data: {},
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result?.event === 'saveChanges') {
                    this.checkIfCanauxEcartes();
                }
            });
        } else {
            this.checkIfCanauxEcartes();
        }
    }

    // Vérifie si les canaux écartés sont présents dans le fichier importé
    public checkIfCanauxEcartes(): void {
        this.isLoading = true;
        let canauxEcartes = {};
        let csvData, isGoogleSheet;
        [csvData, isGoogleSheet] = this.awsService.getCsvData();

        let codesContrat = [];

        if (isGoogleSheet) {
            const headers = csvData[0]; // Récupération des entêtes
            const dataRows = csvData.slice(1); // Les données commencent à partir de la deuxième ligne
            dataRows.forEach((row) => {
                let rowData = {};
                headers.forEach((header, index) => {
                    rowData[header] = row[index] || '';
                });
                codesContrat.push(rowData['code_contrat']); // Ajout de tous les code_contrat à la liste de vérification
            });
        } else {
            codesContrat = csvData.map((item) => item.code_contrat);
        }
        this.awsService.getCanauxEcartes().subscribe(
            (apiData) => {
                const supports = [this.support1, this.support2, this.support3];
                /* @TODO : fix this */
                supports.forEach((support) => {
                    if (support && Array.isArray(apiData[support])) {
                        const codesCorrespondants = apiData[support].filter((apiItem) => codesContrat.includes(apiItem.code_interne));
                        if (codesCorrespondants?.length > 0) {
                            canauxEcartes[support] = codesCorrespondants.map((code) => code.code_interne);
                        }
                    }
                });
                if (Object.keys(canauxEcartes)?.length > 0) {
                    console.info('Canaux écartés :', canauxEcartes);
                    const dialogRef = this.dialog.open(ConfirmCanauxEcartesComponent, {
                        data: {
                            canauxEcartes: canauxEcartes,
                        },
                    });
                    dialogRef.afterClosed().subscribe((result) => {
                        if (result?.event === 'standardSend') {
                            this.generate(false);
                        }
                        if (result?.event === 'forceSend') {
                            this.generate(true);
                        }
                    });
                } else {
                    this.generate();
                }
            },
            (error) => {
                this.utilsService.errorNotification("Erreur lors de la récupération des données de l'API.");
                console.error("Erreur lors de la récupération des données de l'API :", error);
                this.isLoading = false;
            }
        );
    }

    /**
     * Retourne l'heure d'emission. Pour cela, on vérifie d'abord si
     * l'utilisateur n'as pas demandé à étaler ses communications (Ticket #CSE-2339)
     * https://ist-efr.atlassian.net/jira/software/c/projects/CSE/boards/475?assignee=64216a4c67102fc717c1d3b9&assignee=602d50639f84a90069efd76d&selectedIssue=CSE-2339
     * Et dans ce cas, on suit la logique décrite dans le ticket ci-haut.
     */
    private _getEmissionHours = (records) => {
        if (!this.staggerCommunications) {
            return Array(records?.length).fill(this.heureEmission.value);
        }

        let results = [];
        let hour = 8;
        const pas = Math.floor(records?.length / 10);
        let restOfDivision = records?.length % 10;
        let borneSup = pas - 1; // minus 1 because index start at 0
        if (restOfDivision > 0) {
            borneSup += 1;
            restOfDivision -= 1;
        }
        let addZero = true;
        records.forEach((record, cursor) => {
            results.push(`${addZero ? '0' : ''}${hour}:00`);
            if (cursor === borneSup) {
                hour++;
                borneSup += pas;
                addZero = hour < 10;
                if (restOfDivision > 0) {
                    borneSup += 1;
                    restOfDivision -= 1;
                }
            }
        });
        return results;
    };

    convertToDate(input: string): Date | null {
        // Vérifier si la date est déjà au format YYYY-MM-DD
        if (/^\d{4}-\d{2}-\d{2}$/.test(input)) {
            return new Date(input);
        }

        // Gérer les formats DD/MM/YYYY, D/MM/YYYY, DD/M/YYYY, D/M/YYYY avec des années à quatre ou deux chiffres
        const parts = input.match(/(\d{1,2})\/(\d{1,2})\/(\d{2,4})/);
        if (parts) {
            let year = parseInt(parts[3], 10);
            let month = parseInt(parts[2], 10);
            let day = parseInt(parts[1], 10);

            // Convertir les années à deux chiffres en années à quatre chiffres
            if (year < 100) {
                year += year < 50 ? 2000 : 1900; // Assumer que toute année < 50 appartient au 21ème siècle
            }

            // JavaScript compte les mois à partir de 0, donc on soustrait 1 du mois
            month -= 1;

            return new Date(year, month, day);
        }

        return null; // Retourner null si le format est incorrect
    }

    public generate = (isForcedToSend?: boolean) => {
        this.isLoading = true;
        this.loading = 'processing';
        let fillContact = true;
        if (this.isHorsIris === true) {
            fillContact = false;
        }
        // Si fillabo est coché et que le nombre de communications est supérieur à 5000, on augmente la durée du timer
        if (this.fillabo && this.records?.length >= 5000) {
            this.duration = 300;
        } else {
            this.duration = 60;
        }
        this.startTimer();
        this.timeLeft = this.duration;

        let body: any = {};
        const principal = this.support1[0];
        let secours = [];
        if (this.support2) secours.push(this.support2[0]);
        if (this.support3) secours.push(this.support3[0]);
        body.canal = [{ principal, secours }];
        let selectedRoles = Object.keys(this.selectedRecipientTypes).filter((key) => this.selectedRecipientTypes[key]);
        body['roles'] = selectedRoles;

        const emissionHours = this._getEmissionHours(this.records);

        let statusAbn = [];
        if (this.actif) statusAbn.push('Actif');
        if (this.resiled) statusAbn.push('Résilié');
        if (this.aboned) statusAbn.push("En cours d'abonnement");
        if (this.closed) statusAbn.push('En cours de résiliation');
        body['statut-abonnement'] = statusAbn;

        this.awsService.getpresignedurls().subscribe(
            (res) => {
                let data = this.userLoginService.getProfile();
                const date_creation = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
                const date_emission_demandee = this.datepipe.transform(this.date.value, 'YYYY-MM-dd');
                if (this.isDateSeted) this.dateEmision = this.date.value;
                this.data = [];
                this.records.forEach((record, index) => {
                    const jsondata: any = {
                        matricule_emetteur: '',
                        date_creation,
                        id_lot_veolia: res.id_lot_veolia,
                        type_envoi: 'M',
                        systeme_emetteur: 'MCUR',
                        absence_archivage: '0',
                        libelle_document: '',
                        type_affranchissement: this.affranchissement,
                        niveau_affranchissement: 'DP / TF',
                        ind_recto_verso: '1',
                        nb_feuille: '1',
                        ind_tip: '0',
                        enveloppe_porteuse: 'INF100',
                        enveloppe_retour: ' ',
                        echantillon: '0',
                    };
                    if (this.isDateSeted) {
                        jsondata['date_emission'] = date_emission_demandee;
                        jsondata['date_emission_demandee'] = date_emission_demandee + `T${emissionHours[index]}:00.000Z`;
                    }
                    if (record.hasOwnProperty('date_facture') && record.date_facture) {
                        const formattedDate = this.convertToDate(record.date_facture);
                        if (formattedDate) {
                            jsondata['date_facture'] = this.datepipe.transform(formattedDate, 'yyyy-MM-dd');
                        }
                    }
                    // Vérifier si solde_total_ttc existe et le convertir si nécessaire
                    if (record.hasOwnProperty('solde_total_ttc') && record.solde_total_ttc != null) {
                        let soldeTTC = record.solde_total_ttc;

                        // Remplacer la virgule par un point si soldeTTC est une chaîne contenant une virgule
                        if (typeof soldeTTC === 'string' && soldeTTC.includes(',')) {
                            soldeTTC = soldeTTC?.replace(',', '.');
                        }

                        // Conversion du nombre à virgule en nombre avec point si nécessaire
                        jsondata['solde_total_ttc'] = soldeTTC;

                        // Convertir en nombre et calculer solde_facture_ttc
                        const soldeTTCNumber = parseFloat(soldeTTC);
                        /* if (!isNaN(soldeTTCNumber)) {
                            jsondata['solde_facture_ttc'] = (soldeTTCNumber * 100).toString();
                        } */
                    }
                    if (data && data.user && data.user.email) {
                        jsondata['matricule_emetteur'] = data.user.email.split('@')[0];
                    }
                    if (record.hasOwnProperty('telephone_destinataire')) {
                        // Supprimer les espaces dans le numéro de téléphone
                        let telephone = record['telephone_destinataire']?.replace(/\s/g, '');
                        // si le numéro de téléphone n'a pas de 0 au début, on l'ajoute :
                        if (telephone && telephone?.length == 9 && telephone[0] != '0') {
                            telephone = '0' + telephone;
                        }
                        jsondata['telephone_destinataire'] = telephone;
                        jsondata['indicatif_portable'] = '33';
                    }
                    if (this.selectedRegion) {
                        jsondata['code_region'] = this.selectedRegion;
                    }
                    if (this.selectedTerritoire) {
                        jsondata['code_territoire'] = this.selectedTerritoire;
                    }
                    if (record.hasOwnProperty('afnor1')) {
                        jsondata['nom_destinataire'] = record['afnor1'];
                    }
                    if (record.hasOwnProperty('numero_abonnement')) {
                        jsondata['numero_abonnement'] = '';
                    }
                    if (record.hasOwnProperty('email_destinataire')) {
                        let email = record['email_destinataire']?.replace(/\s/g, '');
                        jsondata['email_destinataire'] = email;
                    }
                    const colums = [
                        'date_creation',
                        'date_emission_demandee',
                        'champ_libre_1',
                        'champ_libre_2',
                        'champ_libre_3',
                        'champ_libre_4',
                        'champ_libre_5',
                        'numero_abonnement',
                        'code_contrat',
                        'adresse_branchement',
                        'numero_compteur',
                        'type_client',
                    ];
                    colums.forEach((key) => {
                        let value = String(record[key]);
                        if (record[key]) jsondata[key] = value;
                        if (key == 'numero_abonnement' && !this.isHorsIris) {
                            if (value?.length < 16) {
                                // numero d'abonnement Waterp
                                jsondata['code_traite_abonnement'] = '';
                                jsondata['code_commune_abonnement'] = '';
                            } else {
                                jsondata['code_traite_abonnement'] = value.substring(2, 5);
                                jsondata['code_commune_abonnement'] = value.substring(5, 8);
                            }
                        }
                    });

                    const additionalData = Object.keys(record).filter((key) => {
                        return Object.keys(jsondata).indexOf(key) == -1;
                    });
                    additionalData.forEach((key) => {
                        if (record[key]) jsondata[key] = String(record[key]);
                    });
                    this.data.push(jsondata);
                });

                if (res.url) {
                    const blob = new Blob([JSON.stringify(this.data)], { type: 'text/plain' });
                    const id_lot_veolia = res.id_lot_veolia;
                    const tmpFile = new File([blob], res.id_lot_veolia);
                    const bucket_name = res.url.split('.', 1)[0]?.replace('https', 's3');

                    this.awsService.uploadfileAWSS3(res, tmpFile).subscribe(
                        () => {
                            const data = {
                                ...body,
                                date_creation,
                                'fill-abonnement': this.fillabo,
                                'bucket-fichier': bucket_name + '/' + res.fields.key,
                            };

                            this.awsService.compose(data, isForcedToSend, fillContact).subscribe(
                                (data) => {
                                    setTimeout(() => {
                                        this.next(id_lot_veolia, this.timeLeft, emissionHours);
                                    }, 2 * 1000);
                                },
                                () => this.setErrorLoading()
                            );
                        },
                        () => this.setErrorLoading()
                    );
                } else {
                    this.stopTimer();
                    this.isLoading = false;
                }
            },
            () => this.setErrorLoading()
        );
    };
}
