<mat-form-field appearance="fill" [ngClass]="{'multiple': toppings?.value?.length > 1}">
    <mat-select multiple [formControl]="toppings">
        <mat-select-trigger>
            <div class="elmts" >
                <div *ngFor="let elm of toppings.value;  let i=index">
                    <div *ngIf="i<2" class="elmt">
                        <label>{{elm}}</label>
                        <mat-icon>highlight_off</mat-icon>
                    </div>
                </div>
                <div class="elmt more" *ngIf="toppings?.value?.length > 2">
                    <label>...</label>
                </div>
            </div>
        </mat-select-trigger>
        <mat-option *ngFor="let op of list" [value]="op.label">{{op.label}}</mat-option>
    </mat-select>
</mat-form-field>
