import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { SharedModule } from 'src/app/shared/shared.module';
import { MaterialModule } from 'src/app/material/material.module';
import { ConfigurationRoutingModule } from './configuration-routing.module';

import { HomeComponent } from './pages/home/home.component';
import { ConfigurationComponent } from './configuration.component';
import { ResumeComponent } from './components/resume/resume.component';
import { RecipientsComponent } from './components/recipients/recipients.component';
import { SettingsSendComponent } from './components/settings-send/settings-send.component';
import { ConfigSendingComponent } from './components/config-sending/config-sending.component';
import { ConfirmValidationDialog } from './components/settings-send/confirm-validation/confirm-validation.component';
import { ConfirmCanauxEcartesComponent } from './components/settings-send/confirm-canaux-ecartes/confirm-canaux-ecartes.component';

@NgModule({
    declarations: [HomeComponent, ConfigurationComponent, RecipientsComponent, SettingsSendComponent, ConfirmCanauxEcartesComponent, ConfirmValidationDialog, ConfigSendingComponent, ResumeComponent],
    imports: [
        CommonModule,
        SharedModule,
        ConfigurationRoutingModule,
        NgxSliderModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        NgCircleProgressModule.forRoot({
            'radius': 56,
            'space': -10,
            'outerStrokeGradient': true,
            'outerStrokeWidth': 10,
            'outerStrokeColor': '#4882c2',
            'outerStrokeGradientStopColor': '#53a9ff',
            'innerStrokeColor': '#c7d6f5',
            'innerStrokeWidth': 10,
            'animateTitle': false,
            'animationDuration': 1000,
            'showSubtitle': false,
            'showBackground': false,
            'clockwise': false,
            'lazy': true,
        }),
    ],
})
export class ConfigurationModule {}
