import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'src/app/shared/shared.module';
import { SupportRoutingModule } from './support-routing.module';
import { MaterialModule } from 'src/app/material/material.module';

import { SupportComponent } from './support.component';
import { HelpAndSupportComponent } from './pages/help-and-support/help-and-support.component';

@NgModule({
    declarations: [SupportComponent, HelpAndSupportComponent],
    imports: [CommonModule, SupportRoutingModule, MaterialModule, SharedModule],
})
export class SupportModule {}
