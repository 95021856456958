import { Storage } from 'aws-amplify';
import { Component, OnInit } from '@angular/core';

import { UtilsService } from 'src/app/services/utils.service';

@Component({
    selector: 'app-edit-variables',
    templateUrl: './edit-variables.component.html',
    styleUrls: ['./edit-variables.component.scss'],
})
export class EditVariablesComponent implements OnInit {
    public loading = false;
    public displayedNameColumns: string[] = [];
    public isModified = false;
    public nbVariables: number = 0;
    public fileName: string = 'variables.json';
    public retryCount = 0;

    constructor(private utilsService: UtilsService) {}

    ngOnInit() {
        this.loading = true;
        setTimeout(() => {
            this.loadContent();
        }, 100);
    }

    public async loadContent() {
        this.loading = true;
        Storage.get(this.fileName, { download: true, expires: 10, cacheControl: 'no-cache' })
            .then((result) => {
                const reader = new FileReader();
                reader.onload = (event: any) => {
                    const JSONmessage = JSON.parse(event.target.result);
                    this.displayedNameColumns = JSONmessage.variables;
                    this.nbVariables = this.displayedNameColumns.length;
                    this.utilsService.successNotification('Variables récupérées avec succès.');
                };
                reader.readAsText((result as any).Body);
                this.loading = false;
            })
            .catch((err) => {
                if (this.retryCount < 3) {
                    this.retryCount++;
                    setTimeout(() => {
                        this.loadContent();
                    }, 500);
                } else {
                    this.utilsService.errorNotification('La récupération des variables a échouée.', err);
                    console.error('Erreur: ', err);
                    this.loading = false;
                }
            });
    }

    saveVariableList() {
        this.loading = true;
        Storage.put(this.fileName, JSON.stringify({ variables: this.displayedNameColumns }), {
            contentType: 'application/json',
        })
            .then((result) => {
                this.utilsService.successNotification('Les nouvelles variables ont été enregistrées avec succès.');
                this.loadContent();
                this.loading = false;
            })
            .catch((err) => {
                this.utilsService.errorNotification('La sauvegarde des variables a échouée.', err);
                console.error('Erreur: ', err);
                this.loading = false;
            });
    }

    addVariableField() {
        this.displayedNameColumns.push('');
    }

    deleteVariable(index: number) {
        this.displayedNameColumns.splice(index, 1);
        this.isModified = true;
    }

    onInputChange() {
        this.isModified = true;
    }

    // Permet de ne pas recharger les lignes du tableau à chaque modification
    trackByFn(index: any) {
        return index;
    }
}
