<mat-spinner *ngIf="loading" class="connexion-loading"></mat-spinner>
<div class="page-layout">
  <div class="admin-layout">
    <div style="display: flex; flex-direction: row; align-items: center; gap: 10px; justify-content: space-between;">
      <h5 class="color-blue main-title">Message de la page d'Aide et Support</h5>
      <button class="tertiary-btn" (click)="loadContent()">
        <span>Rafraichir le contenu</span>
        <i class="material-icons" style="color: #0d47a1;">refresh</i>
      </button>
    </div>
    <h6 style="font-size: 13px;">Si le contenu de la page est vide, veuillez cliquer sur le bouton "Rafraichir".</h6>
    <div class="pricing-form-container">
      <editor apiKey="h1eixghlgiohpdsd3nyuiqqx5nkfxm8ewol5tij5jo1kgn5p" id="richTextEditor" [init]="richText"></editor>
    </div>
    <button mat-raised-button color="primary" type="submit" (click)="saveContent()">Sauvegarder le Contenu</button>
  </div>
</div>