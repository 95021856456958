import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { Option } from '../models/option';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class UtilsService {
    private pcos: Option[] = [];

    private pcosSource: BehaviorSubject<Option[]> = new BehaviorSubject<Option[]>(this.pcos);

    public pcos$: Observable<Option[]> = this.pcosSource.asObservable();

    constructor(private http: HttpClient, private toastr: ToastrService) {}

    public compareDate(first: string, last: string): number {
        return new Date(first).setHours(0, 0, 0, 0) - new Date(last).setHours(0, 0, 0, 0);
    }

    public isLeapYear(last: string, first: string): number {
        const start = new Date(first);
        const end = new Date(last);
        const leap = start.getMonth() > 1 ? end.getFullYear() : start.getFullYear();
        return (leap % 4 == 0 && leap % 100 != 0) || leap % 400 == 0 ? 365 : 364;
    }

    public convertDateToRestFormat(date: string): string {
        const time = new Date(date);
        return time.getFullYear() + '-' + this.pad(time.getMonth() + 1) + '-' + this.pad(time.getDate());
    }

    private pad(d) {
        return d < 10 ? '0' + d.toString() : d.toString();
    }

    public successNotification(message: string, title?: string): void {
        this.toastr.success(message, title);
    }

    public errorNotification(message: string, title?: string): void {
        this.toastr.error(message, title, {
            enableHtml: true,
            timeOut: 15000,
        });
    }
}
