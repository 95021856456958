import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminComponent } from './admin.component';
import { MessagesComponent } from './messages/messages.component';
import { PricesComponent } from './prices/prices.component';
import { AuthGuard } from 'src/app/guards/auth-guard.service';
import { EditVariablesComponent } from './edit-variables/edit-variables.component';
import { HelpAndSupportComponent } from './help-and-support/help-and-support.component';
import { CarrouselComponent } from './carrousel/carrousel.component'

const adminRoutes: Routes = [
    {
        path: '',
        redirectTo: 'admin',
        pathMatch: 'full',
    },
    {
        path: 'admin',
        component: AdminComponent,
        children: [
            {
                path: '',
                redirectTo: 'messages',
                pathMatch: 'full',
            },
            {
                path: 'messages',
                component: MessagesComponent,
            },
            {
                path: 'prices',
                component: PricesComponent,
            },
            {
                path: 'variables',
                component: EditVariablesComponent,
            },
            {
                path: 'carrousel',
                component: CarrouselComponent,
            },
            {
                path: 'help-and-support',
                component: HelpAndSupportComponent,
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(adminRoutes)],
    exports: [RouterModule],
    providers: [],
})
export class AdminRoutingModule {}
