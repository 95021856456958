<mat-spinner *ngIf="loading" class="connexion-loading"></mat-spinner>
<div class="page-layout">
  <div class="admin-layout">
    <h5 class="color-blue main-title">Modification des tarifs de communication</h5>
    <div class="pricing-form-container">
      <form [formGroup]="pricingForm" (ngSubmit)="onSubmit()">
        <div class="digital">
          <h5>Communication digitale</h5>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>SMS</mat-label>
              <input matInput formControlName="S" type="number">
              <span class="currency-symbol">€</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Email</mat-label>
              <input matInput formControlName="M" type="number">
              <span class="currency-symbol">€</span>
            </mat-form-field>

            <div class="full"></div>
          </div>
        </div>
        <div class="courrier">
          <h5>Communication par courrier</h5>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Courrier standard</mat-label>
              <input matInput formControlName="C_EGC" type="number">
              <span class="currency-symbol">€</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Courrier en lettre suivie</mat-label>
              <input matInput formControlName="C_ELS" type="number">
              <span class="currency-symbol">€</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Courrier à l'étranger</mat-label>
              <input matInput formControlName="C_ETR" type="number">
              <span class="currency-symbol">€</span>
            </mat-form-field>
          </div>

        </div>
        <div class="recommande">
          <h5>Communication par recommandé</h5>
          <div>
            <mat-form-field appearance="fill">
              <mat-label>Courrier recommandé sans avis de réception</mat-label>
              <input matInput formControlName="C_RRS" type="number">
              <span class="currency-symbol">€</span>
            </mat-form-field>

            <mat-form-field appearance="fill">
              <mat-label>Courrier recommandé avec avis de réception</mat-label>
              <input matInput formControlName="C_RRA" type="number">
              <span class="currency-symbol">€</span>
            </mat-form-field>

            <div class="full"></div>
          </div>
        </div>
        <button mat-raised-button color="primary" type="submit" [disabled]="!objectKeys(changedFields).length">
          Mettre à jour les prix
        </button>
      </form>
    </div>

  </div>
</div>