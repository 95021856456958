import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/guards/auth-guard.service';
import { HelpAndSupportComponent } from './pages/help-and-support/help-and-support.component';
import { SupportComponent } from './support.component';


const supportRoutes: Routes = [
    {
        path: '',
        redirectTo: 'help',
        pathMatch: 'full',
    },
    {
        path: 'help',
        component: SupportComponent,
        children: [
            {
                path: '',
                component: HelpAndSupportComponent,
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(supportRoutes)],
    exports: [RouterModule],
    providers: [],
})
export class SupportRoutingModule {}
