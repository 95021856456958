import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-steps',
    templateUrl: './steps.component.html',
    styleUrls: ['./steps.component.scss'],
})
export class StepsComponent implements OnInit {
    @Input()
    step: number = 1;

    steps = [
        { title: 'Sélection du destinataire', step: 1, icon: 'person_search' },
        { title: "Configuration de l'envoi", step: 2, icon: 'settings' },
        { title: 'Synthèse', step: 3, icon: 'description' },
    ];

    constructor() {}

    ngOnInit(): void {}
}
