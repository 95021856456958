import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/guards/auth-guard.service';
import { HomeComponent } from './pages/home/home.component';
import { ConfigurationComponent } from './configuration.component';


const configurationRoutes: Routes = [
    {
        path: '',
        redirectTo: 'configuration',
        pathMatch: 'full',
    },
    {
        path: 'configuration',
        component: ConfigurationComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
            },
        ],
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(configurationRoutes)],
    exports: [RouterModule],
    providers: [],
})
export class ConfigurationRoutingModule {}
