<div class="bg-primary">
    <header>
        <mat-toolbar color="accent" class="body">
            <div class="buttons-row">
                <a class="container-logo" routerLink="/home" routerLinkActive="active">
                    <img alt="logo" class="logo" src="../assets/img/logo.png" />
                </a>
                <a *ngIf="isAdmin && isLoggedIn" routerLink="/admin" routerLinkActive="active" class="no-underline">
                    <button class="header-btn">
                        <mat-icon aria-hidden="false" class="icon-menu">manage_accounts</mat-icon>
                        Espace administrateur
                    </button>
                </a>
            </div>
            <ng-container *ngIf="isLoggedIn">
                <!-- <div class="nav-container">
                    <ul ngbNav #nav="ngbNav" class="nav-tabs">
                        <li [ngbNavItem]="i" *ngFor="let link of routes[module]; let i = index">
                            <a ngbNavLink class="color-white" [routerLink]="[link.url]" class="link">
                                <mat-icon aria-hidden="false">{{link.icon}}</mat-icon>
                                <p>{{link.label}}</p>
                            </a>
                        </li>
                    </ul>
                </div> -->
                <div>
                    <button mat-button [matMenuTriggerFor]="menu" class="header-btn name">
                        <div class="name">
                            <mat-icon aria-hidden="false" class="icon-menu">person</mat-icon>
                            {{getProfileName()}}
                        </div>
                        <label class="version">{{version}}</label>
                    </button>
                </div>
                <mat-menu #menu="matMenu" class="menu-content" style=" position: relative;">
                    <a href="/help" target="_blank" style="text-decoration: none;"><button mat-menu-item>Aide &
                            Support</button></a>
                    <button mat-menu-item (click)="logout()" style="height: 55px;">Se déconnecter</button>
                </mat-menu>
            </ng-container>
        </mat-toolbar>
    </header>
</div>