import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-select-multiple',
  templateUrl: './select-multiple.component.html',
  styleUrls: ['./select-multiple.component.scss']
})
export class SelectMultipleComponent implements OnInit {

  constructor() { }
  @Input()
  list = [{key: 'factCycl', label: 'Facture cycle'}, {key: 'factES', label: 'Facture E/S'}, {key: 'PerCyc', label: 'Percepteur cycle'}, {key: 'PerES', label: 'Percepteur E/S'}, {key: 'relance', label: 'Relance'}, {key: 'PMen', label: 'Plan de mensualisation'}];
  toppings = new UntypedFormControl();
  ngOnInit(): void {
  }


}
