    <div class="container">
            <div class="select-group">
                <ng-container *ngFor="let item of form; let i = index">
                    <ng-template [ngIf]="item.type == 'date'">
                        <mat-form-field class="example-full-width border-bottom-form">
                            <mat-label>{{item.title}}</mat-label>
                            <input matInput [matDatepicker]="picker" [(ngModel)]="item.value">
                            <mat-datepicker-toggle matSuffix [for]="picker">
                            </mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>
                    </ng-template>
                    <ng-template [ngIf]="item.type == 'select'">
                        <mat-form-field appearance="fill">
                            <mat-label>{{item.title}}</mat-label>
                            <mat-select multiple [(ngModel)]="item.value">
                                <mat-option *ngFor="let op of item.data" [value]="topping">{{op}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-template>
                    <ng-template [ngIf]="item.type == 'text'">
                        <mat-form-field class="input-filter border-bottom-form"  style="width: 190px;">
                            <mat-label>{{item.title}}</mat-label>
                            <input matInput placeholder="{{item.placeholder}}" [(ngModel)]="item.value" [name]='item.title'>
                        </mat-form-field>
                    </ng-template>
                    <ng-template [ngIf]="item.type == 'number'"   style="width: 190px;">
                        <mat-form-field class="input-filter border-bottom-form">
                            <mat-label>{{item.title}}</mat-label>
                            <input matInput placeholder="{{item.placeholder}}" value="" type="number">
                        </mat-form-field>
                    </ng-template>
                    <ng-template [ngIf]="item.type == 'chips'">
                        <mat-form-field class="example-chip-list" appearance="fill">
                            <mat-label>{{item.title}}</mat-label>
                            <mat-chip-list #chipList aria-label="{{item.title}}">
                                <mat-chip *ngFor="let item of getItems(i)" [selectable]="selectable"
                                          [removable]="removable" (removed)="remove(item, i)">
                                    {{item.name}}
                                    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                                </mat-chip>
                                <input placeholder="122222"
                                       [matChipInputFor]="chipList"
                                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                       [matChipInputAddOnBlur]="addOnBlur"
                                       (matChipInputTokenEnd)="add($event, i)">
                            </mat-chip-list>
                        </mat-form-field>
                    </ng-template>
                </ng-container>
            </div>
            <div class="container-button">
                <button class="btn-grey" mat-raised-button>Réinitialiser</button>
                <button class="submit" mat-raised-button color="accent" [disabled]="isFilterEmpty()">Rechercher</button>
            </div>
    </div>
