import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AwsService } from './services/aws.service';
import { environment } from '../environments/environment';
//declare const gapi: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    isLoginIn = false;
    //public auth2: any;

    constructor(public router: Router, public awsService: AwsService) {}

    ngOnInit() {
        /* setTimeout(() => { */
        this.awsService.initAmplify();
        /*  }, 2000); */
    }
}
