import { NgModule } from '@angular/core';
import {HeaderComponent} from "./header/header.component";
import {MaterialModule} from "../material/material.module";
import {CommonModule} from "@angular/common";
import {NgbNavModule} from "@ng-bootstrap/ng-bootstrap";
//import {MonitoringModule} from "../monitoring/monitoring.module";
import { RouterModule } from '@angular/router';
import { DynamicFormComponent } from "./dynamic-form/dynamic-form.component";
import { SelectMultipleComponent } from './select-multiple/select-multiple.component';
import {SharedModule} from '../shared/shared.module';
import { GoogleSigninBtnComponent } from './google-signin/google-signin-btn.component';

@NgModule({
  imports: [
      CommonModule,
      SharedModule,
      MaterialModule,
      NgbNavModule,
      RouterModule
  ],
  declarations: [
    HeaderComponent,
    DynamicFormComponent,
    SelectMultipleComponent,
    GoogleSigninBtnComponent,
  ],
  exports: [
    HeaderComponent,
    DynamicFormComponent
  ],
})

export class ComponentsModule { }
